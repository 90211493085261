"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detectFastMovements = exports.FastMovementType = void 0;
var SMOOTH_MOVEMENT_FACTOR = 0.98;
var ALPHA_DETECTION_MIN = 2.5;
var ALPHA_DETECTION_MAX = 179;
var BETA_DETECTION_MIN = 4;
var BETA_DETECTION_MAX = 89;
var GAMMA_DETECTION_MIN = 4;
var GAMMA_DETECTION_MAX = 89;
/**
 * Enumeration of the different fast movements that can be detected.
 */
var FastMovementType;
(function (FastMovementType) {
    /**
     * The user is walking too fast around the vehicle.
     */
    FastMovementType["WALKING_TOO_FAST"] = "walking_too_fast";
    /**
     * The user is shaking their phone too much.
     */
    FastMovementType["PHONE_SHAKING"] = "phone_shaking";
})(FastMovementType = exports.FastMovementType || (exports.FastMovementType = {}));
/**
 * Function used to detect fast user movements between DeviceOrientationEvent emissions.
 */
function detectFastMovements(rotation, previousRotation) {
    var alpha = rotation.alpha, beta = rotation.beta, gamma = rotation.gamma;
    var prevAlpha = previousRotation.alpha, prevBeta = previousRotation.beta, prevGamma = previousRotation.gamma;
    var alphaSpeed = Math.abs(alpha - prevAlpha) * SMOOTH_MOVEMENT_FACTOR;
    var betaSpeed = Math.abs(beta - prevBeta) * SMOOTH_MOVEMENT_FACTOR;
    var gammaSpeed = Math.abs(gamma - prevGamma) * SMOOTH_MOVEMENT_FACTOR;
    if (prevBeta !== 0 && betaSpeed > BETA_DETECTION_MIN && betaSpeed < BETA_DETECTION_MAX) {
        return FastMovementType.PHONE_SHAKING;
    }
    if (prevGamma !== 0 && gammaSpeed > GAMMA_DETECTION_MIN && gammaSpeed < GAMMA_DETECTION_MAX) {
        return FastMovementType.PHONE_SHAKING;
    }
    if (prevAlpha !== 0 && alphaSpeed > ALPHA_DETECTION_MIN && alphaSpeed < ALPHA_DETECTION_MAX) {
        return FastMovementType.WALKING_TOO_FAST;
    }
    return null;
}
exports.detectFastMovements = detectFastMovements;
