"use strict";
/* eslint-disable no-param-reassign */
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateLaplaceScores = void 0;
/**
 * This function calculates the Laplace Scores for a given pixel array. This score can be used to get a rough estimate
 * of the blurriness of the picture.
 *
 * Picture A is less blurry than picture B if :
 *   calculateLaplaceScores(A).std > calculateLaplaceScores(B).std
 *
 * ***WARNING : To save up memory space, the pixels of the array are modified in place!! Before using this function, be
 * sure to make a copy of the array using the `array.slice()` method (you might have performance issues if you use any
 * other method for the duplication of the array).***
 */
function calculateLaplaceScores(pixels, width, height) {
    for (var i = 0; i < pixels.length; i += 4) {
        pixels[i] = 127;
        pixels[i + 2] = 0;
    }
    var kernel = [
        [0, 1, 0],
        [1, -4, 1],
        [0, 1, 0],
    ];
    var squareSize = Math.round((0.8 * Math.min(height, width)) / 2) * 2;
    var yMin = (height - squareSize) / 2;
    var xMin = (width - squareSize) / 2;
    for (var y = yMin + 1; y < yMin + squareSize - 1; y++) {
        for (var x = xMin + 1; x < xMin + squareSize - 1; x++) {
            var sum = 127;
            var i = (y * width + x) * 4;
            for (var ky = -1; ky <= 1; ky++) {
                for (var kx = -1; kx <= 1; kx++) {
                    var neighborIndex = ((y + ky) * width + (x + kx)) * 4;
                    var neighborGreen = pixels[neighborIndex + 1];
                    sum += kernel[ky + 1][kx + 1] * neighborGreen;
                }
            }
            pixels[i] = sum;
        }
    }
    var laplaceSum = 0;
    for (var y = yMin + 1; y < yMin + squareSize - 1; y++) {
        for (var x = xMin + 1; x < xMin + squareSize - 1; x++) {
            var i = (y * width + x) * 4;
            laplaceSum += pixels[i];
        }
    }
    var laplaceMean = laplaceSum / ((squareSize - 2) * (squareSize - 2));
    var se = 0;
    for (var y = yMin + 1; y < yMin + squareSize - 1; y++) {
        for (var x = xMin + 1; x < xMin + squareSize - 1; x++) {
            var i = (y * width + x) * 4;
            var diff = pixels[i] - laplaceMean;
            se += diff * diff;
        }
    }
    var laplaceStd = Math.sqrt(se / ((squareSize - 2) * (squareSize - 2)));
    return { mean: laplaceMean, std: laplaceStd };
}
exports.calculateLaplaceScores = calculateLaplaceScores;
