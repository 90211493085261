"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddDamageMode = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var analytics_1 = require("@monkvision/analytics");
var types_1 = require("@monkvision/types");
var types_2 = require("../types");
function getInitialMode(addDamage, damageDisclosure) {
    if (damageDisclosure && addDamage === types_1.AddDamage.PART_SELECT) {
        return types_2.CaptureMode.ADD_DAMAGE_PART_SELECT;
    }
    if (damageDisclosure && addDamage === types_1.AddDamage.TWO_SHOT) {
        return types_2.CaptureMode.ADD_DAMAGE_1ST_SHOT;
    }
    return types_2.CaptureMode.SIGHT;
}
/**
 * Custom hook used to switch between sight picture taking and add damage picture taking.
 */
function useAddDamageMode(_a) {
    var addDamage = _a.addDamage, currentScreen = _a.currentScreen, _b = _a.handleOpenGallery, handleOpenGallery = _b === void 0 ? function () { } : _b, _c = _a.damageDisclosure, damageDisclosure = _c === void 0 ? false : _c;
    var _d = (0, react_1.useState)(getInitialMode(addDamage, damageDisclosure)), mode = _d[0], setMode = _d[1];
    var _e = (0, react_1.useState)([]), vehicleParts = _e[0], setVehicleParts = _e[1];
    var trackEvent = (0, analytics_1.useAnalytics)().trackEvent;
    var handleAddDamage = (0, react_1.useCallback)(function () {
        if (addDamage === types_1.AddDamage.TWO_SHOT) {
            setMode(types_2.CaptureMode.ADD_DAMAGE_1ST_SHOT);
            trackEvent('AddDamage Selected', {
                mode: types_2.CaptureMode.ADD_DAMAGE_1ST_SHOT,
            });
        }
        if (addDamage === types_1.AddDamage.PART_SELECT) {
            setMode(types_2.CaptureMode.ADD_DAMAGE_PART_SELECT);
            trackEvent('AddDamage Selected', {
                mode: types_2.CaptureMode.ADD_DAMAGE_PART_SELECT,
            });
        }
    }, []);
    var updatePhotoCaptureModeAfterPictureTaken = (0, react_1.useCallback)(function () {
        setVehicleParts([]);
        setMode(mode === types_2.CaptureMode.ADD_DAMAGE_1ST_SHOT
            ? types_2.CaptureMode.ADD_DAMAGE_2ND_SHOT
            : getInitialMode(addDamage, damageDisclosure));
        if (damageDisclosure) {
            handleOpenGallery();
        }
    }, [mode]);
    var handleCancelAddDamage = (0, react_1.useCallback)(function () {
        trackEvent('AddDamage Canceled', {
            mode: mode,
        });
        if ([types_2.CaptureMode.ADD_DAMAGE_PART_SELECT, types_2.CaptureMode.ADD_DAMAGE_1ST_SHOT].includes(mode) &&
            damageDisclosure) {
            handleOpenGallery();
        }
        setVehicleParts([]);
        setMode(getInitialMode(addDamage, damageDisclosure));
    }, [mode, currentScreen]);
    var handleAddDamagePartsSelected = (0, react_1.useCallback)(function (parts) {
        setVehicleParts(parts);
    }, []);
    var handleValidateVehicleParts = (0, react_1.useCallback)(function () {
        setMode(types_2.CaptureMode.ADD_DAMAGE_PART_SELECT_SHOT);
    }, []);
    return (0, common_1.useObjectMemo)({
        mode: mode,
        vehicleParts: vehicleParts,
        handleAddDamage: handleAddDamage,
        updatePhotoCaptureModeAfterPictureTaken: updatePhotoCaptureModeAfterPictureTaken,
        handleCancelAddDamage: handleCancelAddDamage,
        handleAddDamagePartsSelected: handleAddDamagePartsSelected,
        handleValidateVehicleParts: handleValidateVehicleParts,
    });
}
exports.useAddDamageMode = useAddDamageMode;
