"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUploadQueue = void 0;
var common_1 = require("@monkvision/common");
var network_1 = require("@monkvision/network");
var react_1 = require("react");
var monitoring_1 = require("@monkvision/monitoring");
var types_1 = require("../types");
function createAddImageOptions(upload, inspectionId, siblingId, enableThumbnail, additionalTasks, compliance) {
    if (upload.mode === types_1.CaptureMode.SIGHT) {
        return {
            uploadType: network_1.ImageUploadType.BEAUTY_SHOT,
            picture: upload.picture,
            sightId: upload.sightId,
            tasks: additionalTasks ? (0, common_1.uniq)(__spreadArray(__spreadArray([], upload.tasks, true), additionalTasks, true)) : upload.tasks,
            inspectionId: inspectionId,
            compliance: compliance,
            useThumbnailCaching: enableThumbnail,
        };
    }
    if (upload.mode === types_1.CaptureMode.ADD_DAMAGE_PART_SELECT_SHOT) {
        return {
            uploadType: network_1.ImageUploadType.PART_SELECT_SHOT,
            picture: upload.picture,
            inspectionId: inspectionId,
            vehicleParts: upload.vehicleParts,
            compliance: compliance,
            useThumbnailCaching: enableThumbnail,
        };
    }
    return {
        uploadType: network_1.ImageUploadType.CLOSE_UP_2_SHOT,
        picture: upload.picture,
        siblingKey: "closeup-sibling-key-".concat(siblingId),
        firstShot: upload.mode === types_1.CaptureMode.ADD_DAMAGE_1ST_SHOT,
        inspectionId: inspectionId,
        compliance: compliance,
        useThumbnailCaching: enableThumbnail,
    };
}
/**
 * Custom hook used to generate the UploadQueue (using the `useQueue` hook) for the PhotoCapture component.
 */
function useUploadQueue(_a) {
    var _this = this;
    var inspectionId = _a.inspectionId, apiConfig = _a.apiConfig, additionalTasks = _a.additionalTasks, complianceOptions = _a.complianceOptions, eventHandlers = _a.eventHandlers;
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    var siblingIdRef = (0, react_1.useRef)(0);
    var addImage = (0, network_1.useMonkApi)(apiConfig).addImage;
    return (0, common_1.useQueue)(function (upload) { return __awaiter(_this, void 0, void 0, function () {
        var startTs, uploadDurationMs_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (upload.mode === types_1.CaptureMode.ADD_DAMAGE_1ST_SHOT) {
                        siblingIdRef.current += 1;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    startTs = Date.now();
                    return [4 /*yield*/, addImage(createAddImageOptions(upload, inspectionId, siblingIdRef.current, true, additionalTasks, complianceOptions))];
                case 2:
                    _a.sent();
                    uploadDurationMs_1 = Date.now() - startTs;
                    eventHandlers === null || eventHandlers === void 0 ? void 0 : eventHandlers.forEach(function (handlers) { var _a; return (_a = handlers.onUploadSuccess) === null || _a === void 0 ? void 0 : _a.call(handlers, uploadDurationMs_1); });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    if (err_1 instanceof Error &&
                        (err_1.name === 'TimeoutError' || err_1.message === 'Failed to fetch')) {
                        eventHandlers === null || eventHandlers === void 0 ? void 0 : eventHandlers.forEach(function (handlers) { var _a; return (_a = handlers.onUploadTimeout) === null || _a === void 0 ? void 0 : _a.call(handlers); });
                    }
                    handleError(err_1);
                    throw err_1;
                case 4: return [2 /*return*/];
            }
        });
    }); });
}
exports.useUploadQueue = useUploadQueue;
