"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptureSelection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var CaptureSelection_styles_1 = require("./CaptureSelection.styles");
var Button_1 = require("../Button");
var i18n_1 = require("./i18n");
var hooks_1 = require("./hooks");
/**
 * A single page component that allows the user to select between "Add Damage" or "Photo Capture" workflow.
 */
exports.CaptureSelection = (0, common_1.i18nWrap)(function CaptureSelection(_a) {
    var lang = _a.lang, _b = _a.onAddDamage, onAddDamage = _b === void 0 ? function () { } : _b, _c = _a.onCapture, onCapture = _c === void 0 ? function () { } : _c;
    (0, common_1.useI18nSync)(lang);
    var t = (0, react_i18next_1.useTranslation)().t;
    var palette = (0, common_1.useMonkTheme)().palette;
    var style = (0, hooks_1.useCaptureSelectionStyles)();
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: style.container, "data-testid": 'add-damage-btn' }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: style.contentContainer }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: CaptureSelection_styles_1.styles['textcontainer'] }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ style: CaptureSelection_styles_1.styles['title'] }, { children: t('addDamage.title') })), (0, jsx_runtime_1.jsx)("span", __assign({ style: style.description }, { children: t('addDamage.description') }))] })), (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ style: CaptureSelection_styles_1.styles['button'], icon: 'add', primaryColor: palette.primary.base, onClick: onAddDamage, "data-testid": 'add-damage-btn' }, { children: t('addDamage.button') }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: style.contentContainer }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ style: CaptureSelection_styles_1.styles['title'] }, { children: t('capture.title') })), (0, jsx_runtime_1.jsx)("span", __assign({ style: style.description }, { children: t('capture.description') })), (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ style: CaptureSelection_styles_1.styles['button'], primaryColor: palette.primary.base, onClick: onCapture, "data-testid": 'capture-btn' }, { children: t('capture.button') }))] }))] })));
}, i18n_1.i18nCreateInspection);
