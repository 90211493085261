"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HUDOverlay = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var common_1 = require("@monkvision/common");
var HUDOverlay_styles_1 = require("./HUDOverlay.styles");
var hooks_1 = require("./hooks");
/**
 * Component that displays an overlay on top of the PhotoCapture/DamageDisclosure component that is used
 * to display elements such as error messages, spinning loaders etc.
 */
function HUDOverlay(_a) {
    var isCaptureLoading = _a.isCaptureLoading, captureError = _a.captureError, handle = _a.handle, onRetry = _a.onRetry, inspectionId = _a.inspectionId;
    var t = (0, react_i18next_1.useTranslation)().t;
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var error = (0, hooks_1.useErrorLabel)(captureError, handle, inspectionId);
    var handleRetry = (0, hooks_1.useRetry)({ captureError: captureError, handle: handle, onRetry: onRetry });
    if (!isCaptureLoading && !error) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: HUDOverlay_styles_1.styles['overlay'], "data-testid": 'overlay' }, { children: [!error && isCaptureLoading && (0, jsx_runtime_1.jsx)(common_ui_web_1.Spinner, { size: 80, primaryColor: 'primary' }), error && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: __assign(__assign(__assign({}, HUDOverlay_styles_1.styles['errorMessage']), responsive(HUDOverlay_styles_1.styles['errorMessageMobile'])), responsive(HUDOverlay_styles_1.styles['errorMessageTablet'])) }, { children: error })), handleRetry && ((0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ style: HUDOverlay_styles_1.styles['retryButton'], variant: 'outline', icon: 'refresh', onClick: handleRetry }, { children: t('photo.hud.error.retry') })))] }))] })));
}
exports.HUDOverlay = HUDOverlay;
