"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
var HUDButtons_styles_1 = require("../../../components/HUDButtons/HUDButtons.styles");
exports.styles = {
    backdropContainer: {
        position: 'fixed',
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        transition: 'opacity 0.5s ease-out',
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    elementsContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        width: "calc(98% - (".concat(HUDButtons_styles_1.PHOTO_CAPTURE_HUD_BUTTONS_BAR_WIDTH * 4, "px))"),
        top: '10px',
        bottom: '40px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    elementsContainerPortrait: {
        __media: { portrait: true },
        width: 'auto',
        right: '10px',
        left: '10px',
        bottom: "".concat(HUDButtons_styles_1.PHOTO_CAPTURE_HUD_BUTTONS_BAR_WIDTH * 1.8, "px"),
    },
    topContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '3px',
        alignItems: 'center',
    },
    buttonsContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        gap: '3px',
    },
    closeButtonTwin: {
        padding: '22px',
    },
    closeButton: {
        width: '44px',
        height: '44px',
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        paddingBottom: '5px',
    },
    arrows: {
        display: 'flex',
        justifyContent: 'center',
    },
    arrowGuideline: {
        height: '40px',
    },
    arrowSightTutorial: {
        position: 'fixed',
        bottom: '60px',
        left: "calc((".concat(HUDButtons_styles_1.PHOTO_CAPTURE_HUD_BUTTONS_BAR_WIDTH * 2, "px))"),
        width: '40px',
    },
};
