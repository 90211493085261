"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoCaptureHUDTutorialStyle = void 0;
var common_1 = require("@monkvision/common");
var PhotoCaptureHUDTutorial_styles_1 = require("./PhotoCaptureHUDTutorial.styles");
function usePhotoCaptureHUDTutorialStyle() {
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    return {
        elementsContainer: __assign(__assign({}, PhotoCaptureHUDTutorial_styles_1.styles['elementsContainer']), responsive(PhotoCaptureHUDTutorial_styles_1.styles['elementsContainerPortrait'])),
    };
}
exports.usePhotoCaptureHUDTutorialStyle = usePhotoCaptureHUDTutorialStyle;
