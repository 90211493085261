"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVehicleDynamicWireframe = void 0;
var sights_1 = require("@monkvision/sights");
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var VehicleDynamicWireframe_style_1 = require("./VehicleDynamicWireframe.style");
function isCarPartElement(element) {
    return element.id !== '' && element.classList.contains('car-part');
}
function getWireframes(vehicleType, orientation) {
    var wireframes = sights_1.partSelectionWireframes[(0, common_1.getVehicleModel)(vehicleType)];
    if (wireframes === undefined) {
        throw new Error("No wireframe found for vehicle type ".concat(vehicleType));
    }
    return wireframes[orientation];
}
function useVehicleDynamicWireframe(_a) {
    var vehicleType = _a.vehicleType, _b = _a.orientation, orientation = _b === void 0 ? types_1.PartSelectionOrientation.FRONT_LEFT : _b, _c = _a.onClickPart, onClickPart = _c === void 0 ? function () { } : _c, _d = _a.getPartAttributes, getPartAttributes = _d === void 0 ? function () { return ({}); } : _d;
    var overlay = (0, react_1.useMemo)(function () { return getWireframes(vehicleType, orientation); }, [vehicleType, orientation]);
    var getAttributes = (0, react_1.useCallback)(function (element, groups) {
        var groupElement = groups[0];
        var part;
        if (groupElement && isCarPartElement(groupElement)) {
            part = groupElement.id;
        }
        else if (isCarPartElement(element)) {
            part = element.id;
        }
        else {
            return { style: VehicleDynamicWireframe_style_1.styles['notCarPart'] };
        }
        var attributes = getPartAttributes(part);
        if (element.tagName === 'g') {
            delete attributes.style;
        }
        if (element.classList.contains('selectable') && element.id) {
            attributes.onClick = function () { return onClickPart(part); };
            attributes.style = __assign(__assign({}, attributes.style), VehicleDynamicWireframe_style_1.styles['selectable']);
        }
        return attributes;
    }, [onClickPart, getPartAttributes]);
    return { overlay: overlay, getAttributes: getAttributes };
}
exports.useVehicleDynamicWireframe = useVehicleDynamicWireframe;
