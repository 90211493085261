"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.styles = {
    container: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    hudContainer: {
        position: 'absolute',
        inset: '0 0 0 0',
    },
};
