"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMonkAppState = exports.MonkAppStateProvider = exports.STORAGE_KEY_AUTH_TOKEN = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("@monkvision/types");
var sights_1 = require("@monkvision/sights");
var react_1 = require("react");
var hooks_1 = require("../hooks");
var searchParams_1 = require("./searchParams");
var appState_1 = require("./appState");
var monitoring_1 = require("./monitoring");
var analytics_1 = require("./analytics");
var utils_1 = require("../utils");
/**
 * Local storage key used within Monk web applications to store the authentication token.
 */
exports.STORAGE_KEY_AUTH_TOKEN = '@monk_authToken';
function getSights(config, vehicleType, steeringWheel) {
    var currentVehicleType = vehicleType !== null && vehicleType !== void 0 ? vehicleType : config.defaultVehicleType;
    var sightIds;
    if (config.enableSteeringWheelPosition) {
        var currentSteeringWheel = steeringWheel !== null && steeringWheel !== void 0 ? steeringWheel : config.defaultSteeringWheelPosition;
        sightIds = config.sights[currentSteeringWheel][currentVehicleType];
    }
    else {
        sightIds = config.sights[currentVehicleType];
    }
    if (!sightIds) {
        throw new Error('Invalid application configuration. No sights have been found for the current vehicle type steering wheel position.');
    }
    return sightIds.map(function (id) {
        if (!sights_1.sights[id]) {
            throw new Error("Sight with ID \"".concat(id, "\" does not exist."));
        }
        return sights_1.sights[id];
    });
}
/**
 * A React context provider that declares the state for the common parameters used by Monk applications. The parameters
 * are described in the `MonkAppState` interface. Using options available in the App config (`config` prop),  this
 * component can also fetch initial values for these params directly from the URL search params and the web local
 * storage.
 *
 * @see MonkAppState
 * @see MonkAppStateProviderProps
 */
function MonkAppStateProvider(_a) {
    var config = _a.config, onFetchAuthToken = _a.onFetchAuthToken, onFetchLanguage = _a.onFetchLanguage, children = _a.children;
    var loading = (0, hooks_1.useLoadingState)(true);
    var _b = (0, react_1.useState)(null), authToken = _b[0], setAuthToken = _b[1];
    var _c = (0, react_1.useState)(null), inspectionId = _c[0], setInspectionId = _c[1];
    var _d = (0, react_1.useState)(null), vehicleType = _d[0], setVehicleType = _d[1];
    var _e = (0, react_1.useState)(null), steeringWheel = _e[0], setSteeringWheel = _e[1];
    var availableVehicleTypes = (0, react_1.useMemo)(function () {
        return config.workflow === types_1.CaptureWorkflow.PHOTO ? (0, utils_1.getAvailableVehicleTypes)(config) : undefined;
    }, [config]);
    var monkSearchParams = (0, searchParams_1.useMonkSearchParams)({ availableVehicleTypes: availableVehicleTypes });
    var isMounted = (0, hooks_1.useIsMounted)();
    (0, monitoring_1.useAppStateMonitoring)({ authToken: authToken, inspectionId: inspectionId, vehicleType: vehicleType, steeringWheel: steeringWheel });
    (0, analytics_1.useAppStateAnalytics)({ inspectionId: inspectionId });
    (0, react_1.useEffect)(function () {
        var _a;
        loading.onSuccess();
        var fetchedToken = localStorage.getItem(exports.STORAGE_KEY_AUTH_TOKEN);
        if (config.fetchFromSearchParams) {
            fetchedToken = (_a = monkSearchParams.get(searchParams_1.MonkSearchParam.TOKEN)) !== null && _a !== void 0 ? _a : fetchedToken;
            setInspectionId(function (param) { var _a; return (_a = monkSearchParams.get(searchParams_1.MonkSearchParam.INSPECTION_ID)) !== null && _a !== void 0 ? _a : param; });
            setVehicleType(function (param) { var _a; return (_a = monkSearchParams.get(searchParams_1.MonkSearchParam.VEHICLE_TYPE)) !== null && _a !== void 0 ? _a : param; });
            setSteeringWheel(function (param) { var _a; return (_a = monkSearchParams.get(searchParams_1.MonkSearchParam.STEERING_WHEEL)) !== null && _a !== void 0 ? _a : param; });
            var lang = monkSearchParams.get(searchParams_1.MonkSearchParam.LANGUAGE);
            if (lang && isMounted()) {
                onFetchLanguage === null || onFetchLanguage === void 0 ? void 0 : onFetchLanguage(lang);
            }
        }
        if (fetchedToken && isMounted()) {
            setAuthToken(fetchedToken);
            onFetchAuthToken === null || onFetchAuthToken === void 0 ? void 0 : onFetchAuthToken();
        }
    }, [monkSearchParams, config]);
    var getCurrentSights = (0, react_1.useMemo)(function () {
        return config.workflow === types_1.CaptureWorkflow.PHOTO
            ? function () { return getSights(config, vehicleType, steeringWheel); }
            : undefined;
    }, [config, vehicleType, steeringWheel]);
    var appState = (0, react_1.useMemo)(function () {
        return config.workflow === types_1.CaptureWorkflow.VIDEO
            ? {
                loading: loading,
                config: config,
                authToken: authToken,
                inspectionId: inspectionId,
                setAuthToken: setAuthToken,
                setInspectionId: setInspectionId,
            }
            : {
                loading: loading,
                config: config,
                authToken: authToken,
                inspectionId: inspectionId,
                vehicleType: vehicleType,
                availableVehicleTypes: availableVehicleTypes,
                steeringWheel: steeringWheel,
                getCurrentSights: getCurrentSights,
                setAuthToken: setAuthToken,
                setInspectionId: setInspectionId,
                setVehicleType: setVehicleType,
                setSteeringWheel: setSteeringWheel,
            };
    }, [
        loading,
        config,
        authToken,
        inspectionId,
        vehicleType,
        availableVehicleTypes,
        steeringWheel,
        getCurrentSights,
        setAuthToken,
        setInspectionId,
        setVehicleType,
        setSteeringWheel,
    ]);
    return (0, jsx_runtime_1.jsx)(appState_1.MonkAppStateContext.Provider, __assign({ value: appState }, { children: children }));
}
exports.MonkAppStateProvider = MonkAppStateProvider;
function useMonkAppState(options) {
    var value = (0, react_1.useContext)(appState_1.MonkAppStateContext);
    if (!value) {
        throw new Error('MonkAppState is null! Did you forget to place the <MonkAppStateProvider /> component?');
    }
    if ((options === null || options === void 0 ? void 0 : options.requireInspection) && !value.authToken) {
        throw new Error('Authentication token is null but was required by the current component.');
    }
    if ((options === null || options === void 0 ? void 0 : options.requireInspection) && !value.inspectionId) {
        throw new Error('Inspection ID is null but was required by the current component.');
    }
    if ((options === null || options === void 0 ? void 0 : options.requireWorkflow) && value.config.workflow !== (options === null || options === void 0 ? void 0 : options.requireWorkflow)) {
        throw new Error('The capture workflow is different than the one required by the current component.');
    }
    return value;
}
exports.useMonkAppState = useMonkAppState;
