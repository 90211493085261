"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SteeringWheelPosition = exports.PartSelectionOrientation = exports.VehicleModel = exports.VehicleType = exports.SightCategory = void 0;
/**
 * The category of a Sight.
 */
var SightCategory;
(function (SightCategory) {
    /**
     * The category for sights representing the interior of a car.
     */
    SightCategory["INTERIOR"] = "interior";
    /**
     * The category for sights representing the exterior of a car.
     */
    SightCategory["EXTERIOR"] = "exterior";
    /**
     * The category for sights representing miscellaneous parts of a car (VIN number...)
     */
    SightCategory["MISC"] = "misc";
})(SightCategory = exports.SightCategory || (exports.SightCategory = {}));
/**
 * The different types of vehicle available in the Sights package.
 */
var VehicleType;
(function (VehicleType) {
    /**
     * A compact or subcompact sedan with a squared-off roof and a rear flip-up hatch door that provides access to the
     * vehicle's cargo area instead of a conventional trunk.
     */
    VehicleType["HATCHBACK"] = "hatchback";
    /**
     * A vehicle that is built on a car platform but has an increased ride height with a higher ground clearance like an
     * SUV, that can handle any terrain.
     */
    VehicleType["CUV"] = "cuv";
    /**
     * A light-duty truck that has an enclosed cabin, and a back end made up of a cargo bed that is enclosed by three low
     * walls with no roof.
     */
    VehicleType["PICKUP"] = "pickup";
    /**
     * A small car designed to be used primarily in urban areas and conurbations.
     */
    VehicleType["CITY"] = "city";
    /**
     * A type of road vehicle used for transporting goods or people.
     */
    VehicleType["VAN"] = "van";
    /**
     * A 4-door passenger car with a trunk that is separate from the passengers with a three-box body: the engine, the
     * area for passengers, and the trunk.
     */
    VehicleType["SEDAN"] = "sedan";
    /**
     * A large SUV, distinguishable by its ability to tow large items, handle rough terrain, contain plenty of space for
     * passengers and cargo, and has 3 rows of seats.
     */
    VehicleType["LARGE_SUV"] = "large-suv";
    /**
     * A vehicle designed to transport passengers in the rear seating row(s), with reconfigurable seats in two or three
     * rows.
     */
    VehicleType["MINIVAN"] = "minivan";
    /**
     * "Sports Utility Vehicle" : A sleek looking vehicles that offer elegant city driving but also handle rugged terrain
     * thanks to a typical 4x4 capability.
     */
    VehicleType["SUV"] = "suv";
    /**
     * Huge transportation trucks.
     */
    VehicleType["HGV"] = "hgv";
})(VehicleType = exports.VehicleType || (exports.VehicleType = {}));
/**
 * The different vehicle models used in the Sights package.
 */
var VehicleModel;
(function (VehicleModel) {
    /**
     * All vehicle types. This vehicle type is used by sights that can be used in any vehicle.
     */
    VehicleModel["ALL"] = "all";
    /**
     * Audi A7 (Hatchback)
     */
    VehicleModel["AUDIA7"] = "audia7";
    /**
     * Ford Escape SE 2020 (CUV)
     */
    VehicleModel["FESC20"] = "fesc20";
    /**
     * Ford F-150 Super Cab XL 2014 (Pickup)
     */
    VehicleModel["FF150"] = "ff150";
    /**
     * Ford Focus (City)
     */
    VehicleModel["FFOCUS18"] = "ffocus18";
    /**
     * Ford Transit Fourgon L3H2 Trendline 2018 (Van)
     */
    VehicleModel["FTRANSIT18"] = "ftransit18";
    /**
     * Honda Accord Sedan Sport US spec 2018 (Sedan)
     */
    VehicleModel["HACCORD"] = "haccord";
    /**
     * Jeep Grand Cherokee L Summit 2021 (Large SUV)
     */
    VehicleModel["JGC21"] = "jgc21";
    /**
     * Toyota Sienna Limited 2020 (Minivan)
     */
    VehicleModel["TSIENNA20"] = "tsienna20";
    /**
     * Volkswagen T-Roc (SUV)
     */
    VehicleModel["VWTROC"] = "vwtroc";
    /**
     * Man 12 (HGV)
     */
    VehicleModel["MAN12"] = "man12";
    /**
     * Tesla Model 3 (sedan)
     */
    VehicleModel["TESLAM3"] = "teslam3";
})(VehicleModel = exports.VehicleModel || (exports.VehicleModel = {}));
/**
 * The different orientations of a part selection wireframe.
 */
var PartSelectionOrientation;
(function (PartSelectionOrientation) {
    /**
     * Wireframe of the vehicle when looking at its front left side.
     */
    PartSelectionOrientation["FRONT_LEFT"] = "front-left";
    /**
     * Wireframe of the vehicle when looking at its front right side.
     */
    PartSelectionOrientation["FRONT_RIGHT"] = "front-right";
    /**
     * Wireframe of the vehicle when looking at its rear left side.
     */
    PartSelectionOrientation["REAR_LEFT"] = "rear-left";
    /**
     * Wireframe of the vehicle when looking at its rear right side.
     */
    PartSelectionOrientation["REAR_RIGHT"] = "rear-right";
})(PartSelectionOrientation = exports.PartSelectionOrientation || (exports.PartSelectionOrientation = {}));
/**
 * Enumeration of the two positions in which the steering wheel can be.
 */
var SteeringWheelPosition;
(function (SteeringWheelPosition) {
    /**
     * Steering wheel on the left (France, US etc.).
     */
    SteeringWheelPosition["LEFT"] = "left";
    /**
     * Steering wheel on the right (UK etc.).
     */
    SteeringWheelPosition["RIGHT"] = "right";
})(SteeringWheelPosition = exports.SteeringWheelPosition || (exports.SteeringWheelPosition = {}));
