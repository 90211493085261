"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useObjectTranslation = void 0;
var react_i18next_1 = require("react-i18next");
var react_1 = require("react");
var i18n_1 = require("../i18n");
/**
 * Custom hook used to get a translation function tObj that translates TranslationObjects.
 */
function useObjectTranslation(ns, options) {
    var i18n = (0, react_i18next_1.useTranslation)(ns, options).i18n;
    var language = (options === null || options === void 0 ? void 0 : options.lng) ? options.lng : i18n.language;
    var tObj = (0, react_1.useCallback)(function (obj) {
        var _a;
        return (_a = obj[(0, i18n_1.getLanguage)(language)]) !== null && _a !== void 0 ? _a : 'translation-not-found';
    }, [i18n.language]);
    return { tObj: tObj };
}
exports.useObjectTranslation = useObjectTranslation;
