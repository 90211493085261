"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEnforceOrientation = void 0;
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
/**
 * Custom hook used to check if the current device's orientation is violating the enforced orientation. Returns true if
 * an orientation is being enforced and is not matching the current device orientation.
 */
function useEnforceOrientation(orientation) {
    var dimensions = (0, common_1.useWindowDimensions)();
    return !!orientation && (orientation === types_1.DeviceOrientation.PORTRAIT) !== dimensions.isPortrait;
}
exports.useEnforceOrientation = useEnforceOrientation;
