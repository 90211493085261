"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DamageDisclosureHUDElements = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("../../../types");
var components_1 = require("../../../components");
/**
 * Component implementing an HUD displayed on top of the Camera preview during the DamageDisclosure process.
 */
function DamageDisclosureHUDElements(params) {
    if (params.isLoading || !!params.error) {
        return null;
    }
    if (params.mode === types_1.CaptureMode.ADD_DAMAGE_1ST_SHOT) {
        return (0, jsx_runtime_1.jsx)(components_1.ZoomOutShot, { onCancel: params.onCancelAddDamage });
    }
    if ([types_1.CaptureMode.ADD_DAMAGE_2ND_SHOT, types_1.CaptureMode.ADD_DAMAGE_PART_SELECT_SHOT].includes(params.mode)) {
        return ((0, jsx_runtime_1.jsx)(components_1.CloseUpShot, { onCancel: params.onCancelAddDamage, streamDimensions: params.previewDimensions, showCounter: params.mode === types_1.CaptureMode.ADD_DAMAGE_2ND_SHOT }));
    }
    return ((0, jsx_runtime_1.jsx)(components_1.PartSelection, { vehicleType: params.vehicleType, onCancel: params.onCancelAddDamage, vehicleParts: params.vehicleParts, onValidateVehicleParts: params.onValidateVehicleParts, onAddDamagePartsSelected: params.onAddDamagePartsSelected }));
}
exports.DamageDisclosureHUDElements = DamageDisclosureHUDElements;
