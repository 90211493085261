"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleWalkaroundIndicator = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var VehicleWalkaroundIndicator_styles_1 = require("./VehicleWalkaroundIndicator.styles");
/**
 * Component used to display a position indicator to the user when they are walking around their vehicle in the
 * VideoCapture process.
 */
function VehicleWalkaroundIndicator(_a) {
    var alpha = _a.alpha, _b = _a.size, size = _b === void 0 ? 60 : _b;
    var _c = (0, VehicleWalkaroundIndicator_styles_1.useVehicleWalkaroundIndicatorStyles)({
        alpha: alpha,
        size: size,
    }), svgProps = _c.svgProps, steps = _c.steps, progressBarProps = _c.progressBarProps, knobProps = _c.knobProps;
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({}, svgProps, { children: [steps.map(function (_a) {
                var stepAlpha = _a.alpha, props = _a.props;
                return ((0, jsx_runtime_1.jsx)("circle", __assign({}, props), stepAlpha));
            }), (0, jsx_runtime_1.jsx)("circle", __assign({}, progressBarProps, { "data-testid": 'progress-bar' })), (0, jsx_runtime_1.jsx)("circle", __assign({}, knobProps, { "data-testid": 'knob' }))] })));
}
exports.VehicleWalkaroundIndicator = VehicleWalkaroundIndicator;
