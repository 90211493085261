"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DamageDisclosureHOC = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var i18n_1 = require("../i18n");
var DamageDisclosure_1 = require("./DamageDisclosure");
/**
 * The DamageDisclosure component is a ready-to-use, single page component that implements a Camera app, allowing users
 * to capture photos of damaged parts of their vehicle for the purpose of disclosing damage. In order to use this
 * component, you first need to generate an Auth0 authentication token, and create an inspection using the Monk Api.
 * When creating the inspection, don't forget to set the tasks statuses to `NOT_STARTED`. You can then pass the
 * inspection ID, the api config (with the auth token) and everything will be handled automatically for you.
 *
 * @example
 * import { DamageDisclosure } from '@monkvision/inspection-capture-web';
 *
 * export function PhotoCaptureScreen({ inspectionId, apiConfig }: PhotoCaptureScreenProps) {
 *   const { i18n } = useTranslation();
 *
 *   return (
 *     <DamageDisclosure
 *       inspectionId={inspectionId}
 *       apiConfig={apiConfig}
 *       compliances={{ iqa: true }}
 *       onComplete={() => { / * Navigate to another page * / }}
 *       lang={i18n.language}
 *     />
 *   );
 * }
 */
exports.DamageDisclosureHOC = (0, common_1.i18nWrap)(function DamageDisclosureHOC(props) {
    return ((0, jsx_runtime_1.jsx)(common_1.MonkProvider, { children: (0, jsx_runtime_1.jsx)(DamageDisclosure_1.DamageDisclosure, __assign({}, props)) }));
}, i18n_1.i18nInspectionCaptureWeb);
