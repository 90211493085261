"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SightGuideline = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var types_1 = require("@monkvision/types");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var SightGuideline_styles_1 = require("./SightGuideline.styles");
var hooks_1 = require("../../../../hooks");
/**
 * Custom button displaying the sight guideline.
 */
function SightGuideline(_a) {
    var sightId = _a.sightId, sightGuidelines = _a.sightGuidelines, enableSightGuidelines = _a.enableSightGuidelines, addDamage = _a.addDamage, _b = _a.enableDefaultMessage, enableDefaultMessage = _b === void 0 ? false : _b;
    var _c = (0, react_1.useState)(true), showGuideline = _c[0], setShowGuideline = _c[1];
    var primaryColor = (0, hooks_1.useColorBackground)();
    var _d = (0, react_i18next_1.useTranslation)(), i18n = _d.i18n, t = _d.t;
    var style = addDamage === types_1.AddDamage.DISABLED ? SightGuideline_styles_1.styles['containerWide'] : SightGuideline_styles_1.styles['container'];
    var guidelineFound = sightGuidelines === null || sightGuidelines === void 0 ? void 0 : sightGuidelines.find(function (value) { return value.sightIds.includes(sightId); });
    var defaultMessage = enableDefaultMessage
        ? t('photo.hud.guidelines.defaultGuideline')
        : undefined;
    var guideline = guidelineFound ? guidelineFound[(0, common_1.getLanguage)(i18n.language)] : defaultMessage;
    (0, react_1.useEffect)(function () { return setShowGuideline(true); }, [sightId]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ style: style }, { children: enableSightGuidelines && showGuideline && guideline && ((0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ icon: 'close', primaryColor: primaryColor, style: SightGuideline_styles_1.styles['button'], onClick: function () { return setShowGuideline(false); } }, { children: guideline }))) })));
}
exports.SightGuideline = SightGuideline;
