"use strict";
/* eslint-disable no-param-reassign */
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVideoUploadQueue = void 0;
var common_1 = require("@monkvision/common");
var network_1 = require("@monkvision/network");
var react_1 = require("react");
/**
 * Hook used to manage the video frame upload queue.
 */
function useVideoUploadQueue(_a) {
    var apiConfig = _a.apiConfig, inspectionId = _a.inspectionId, maxRetryCount = _a.maxRetryCount;
    var frameIndex = (0, react_1.useRef)(0);
    var frameTimestamp = (0, react_1.useRef)(null);
    var addImage = (0, network_1.useMonkApi)(apiConfig).addImage;
    var queue = (0, common_1.useQueue)(function (upload) {
        return addImage({
            uploadType: network_1.ImageUploadType.VIDEO_FRAME,
            inspectionId: inspectionId,
            picture: upload.picture,
            frameIndex: upload.frameIndex,
            timestamp: upload.timestamp,
        });
    }, {
        storeFailedItems: true,
        onItemFail: function (upload) {
            upload.retryCount += 1;
            if (upload.retryCount <= maxRetryCount) {
                queue.push(upload);
            }
        },
    });
    var onFrameSelected = (0, react_1.useCallback)(function (picture) {
        var now = Date.now();
        var upload = {
            retryCount: 0,
            picture: picture,
            frameIndex: frameIndex.current,
            timestamp: frameTimestamp.current === null ? 0 : now - frameTimestamp.current,
        };
        queue.push(upload);
        frameIndex.current += 1;
        frameTimestamp.current = now;
    }, [queue.push]);
    return (0, common_1.useObjectMemo)({
        uploadedFrames: queue.totalItems - queue.processingCount,
        totalUploadingFrames: queue.totalItems,
        onFrameSelected: onFrameSelected,
    });
}
exports.useVideoUploadQueue = useVideoUploadQueue;
