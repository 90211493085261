"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFastMovementsDetection = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var fastMovementsDetection_1 = require("./fastMovementsDetection");
/**
 * Custom hook used to display warnings to the user when they walk too fast around the car or shake their phone too
 * much.
 */
function useFastMovementsDetection(_a) {
    var _b;
    var isRecording = _a.isRecording, enableFastWalkingWarning = _a.enableFastWalkingWarning, enablePhoneShakingWarning = _a.enablePhoneShakingWarning, fastWalkingWarningCooldown = _a.fastWalkingWarningCooldown, phoneShakingWarningCooldown = _a.phoneShakingWarningCooldown;
    var _c = (0, react_1.useState)(null), fastMovementsWarning = _c[0], setFastMovementsWarning = _c[1];
    var lastRotation = (0, react_1.useRef)({ alpha: 0, beta: 0, gamma: 0 });
    var warningTimestamps = (0, react_1.useRef)((_b = {},
        _b[fastMovementsDetection_1.FastMovementType.WALKING_TOO_FAST] = 0,
        _b[fastMovementsDetection_1.FastMovementType.PHONE_SHAKING] = 0,
        _b));
    var isWarningEnabled = (0, react_1.useCallback)(function (type) {
        switch (type) {
            case fastMovementsDetection_1.FastMovementType.WALKING_TOO_FAST:
                return enableFastWalkingWarning;
            case fastMovementsDetection_1.FastMovementType.PHONE_SHAKING:
                return enablePhoneShakingWarning;
            default:
                return false;
        }
    }, [enableFastWalkingWarning, enablePhoneShakingWarning]);
    var getWarningCooldown = (0, react_1.useCallback)(function (type) {
        switch (type) {
            case fastMovementsDetection_1.FastMovementType.WALKING_TOO_FAST:
                return fastWalkingWarningCooldown;
            case fastMovementsDetection_1.FastMovementType.PHONE_SHAKING:
                return phoneShakingWarningCooldown;
            default:
                return Infinity;
        }
    }, [fastWalkingWarningCooldown, phoneShakingWarningCooldown]);
    var onDeviceOrientationEvent = (0, react_1.useCallback)(function (event) {
        var _a, _b, _c;
        var alpha = (_a = event.alpha) !== null && _a !== void 0 ? _a : 0;
        var beta = (_b = event.beta) !== null && _b !== void 0 ? _b : 0;
        var gamma = (_c = event.gamma) !== null && _c !== void 0 ? _c : 0;
        if (isRecording) {
            var now = Date.now();
            var type = (0, fastMovementsDetection_1.detectFastMovements)({ alpha: alpha, beta: beta, gamma: gamma }, lastRotation.current);
            if (type &&
                isWarningEnabled(type) &&
                Date.now() - warningTimestamps.current[type] > getWarningCooldown(type)) {
                setFastMovementsWarning(type);
                warningTimestamps.current[type] = now;
            }
        }
        lastRotation.current = { alpha: alpha, beta: beta, gamma: gamma };
    }, [isRecording, isWarningEnabled, getWarningCooldown]);
    var onWarningDismiss = (0, react_1.useCallback)(function () { return setFastMovementsWarning(null); }, []);
    return (0, common_1.useObjectMemo)({
        onDeviceOrientationEvent: onDeviceOrientationEvent,
        fastMovementsWarning: fastMovementsWarning,
        onWarningDismiss: onWarningDismiss,
    });
}
exports.useFastMovementsDetection = useFastMovementsDetection;
