"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVideoRecording = exports.VideoRecordingTooltip = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var hooks_1 = require("../../hooks");
/**
 * Enumeration of the different tooltips displayed on top of the recording button during the recording process.
 */
var VideoRecordingTooltip;
(function (VideoRecordingTooltip) {
    /**
     * Tooltip displayed before the recording has been started, to indicate to the user where to press to start the
     * recording.
     */
    VideoRecordingTooltip["START"] = "start";
    /**
     * Tooltip displayed at the end of the recording, to indicate to the user where to press to stop the recording.
     */
    VideoRecordingTooltip["END"] = "end";
})(VideoRecordingTooltip = exports.VideoRecordingTooltip || (exports.VideoRecordingTooltip = {}));
var MINIMUM_VEHICLE_WALKAROUND_POSITION = 270;
/**
 * Custom hook used to manage the video recording (AKA : The process of taking screenshots of the video stream at a
 * given interval).
 */
function useVideoRecording(_a) {
    var isRecording = _a.isRecording, setIsRecording = _a.setIsRecording, screenshotInterval = _a.screenshotInterval, minRecordingDuration = _a.minRecordingDuration, enforceOrientation = _a.enforceOrientation, walkaroundPosition = _a.walkaroundPosition, startWalkaround = _a.startWalkaround, onCaptureVideoFrame = _a.onCaptureVideoFrame, onRecordingComplete = _a.onRecordingComplete;
    var _b = (0, react_1.useState)(false), isRecordingPaused = _b[0], setIsRecordingPaused = _b[1];
    var _c = (0, react_1.useState)(0), additionalRecordingDuration = _c[0], setAdditionalRecordingDuration = _c[1];
    var _d = (0, react_1.useState)(null), recordingStartTimestamp = _d[0], setRecordingStartTimestamp = _d[1];
    var _e = (0, react_1.useState)(false), isDiscardDialogDisplayed = _e[0], setDiscardDialogDisplayed = _e[1];
    var _f = (0, react_1.useState)(false), orientationPause = _f[0], setOrientationPause = _f[1];
    var _g = (0, react_1.useState)(VideoRecordingTooltip.START), tooltip = _g[0], setTooltip = _g[1];
    var isViolatingEnforcedOrientation = (0, hooks_1.useEnforceOrientation)(enforceOrientation);
    var getRecordingDurationMs = (0, react_1.useCallback)(function () {
        return additionalRecordingDuration +
            (recordingStartTimestamp ? Date.now() - recordingStartTimestamp : 0);
    }, [additionalRecordingDuration, recordingStartTimestamp]);
    var pauseRecording = (0, react_1.useCallback)(function () {
        setIsRecordingPaused(function (isRecordingPausedValue) {
            if (!isRecordingPausedValue) {
                setIsRecording(false);
                setRecordingStartTimestamp(function (recordingStartTimestampValue) {
                    setAdditionalRecordingDuration(function (value) {
                        return recordingStartTimestampValue
                            ? value + Date.now() - recordingStartTimestampValue
                            : value;
                    });
                    return null;
                });
            }
            return true;
        });
    }, []);
    var resumeRecording = (0, react_1.useCallback)(function () {
        setIsRecordingPaused(function (isRecordingPausedValue) {
            if (isRecordingPausedValue) {
                setRecordingStartTimestamp(Date.now());
                setIsRecording(true);
            }
            return false;
        });
    }, []);
    var onClickRecordVideo = (0, react_1.useCallback)(function () {
        if (isRecording) {
            if (getRecordingDurationMs() < minRecordingDuration ||
                walkaroundPosition < MINIMUM_VEHICLE_WALKAROUND_POSITION) {
                pauseRecording();
                setDiscardDialogDisplayed(true);
            }
            else {
                setIsRecording(false);
                onRecordingComplete === null || onRecordingComplete === void 0 ? void 0 : onRecordingComplete();
            }
        }
        else {
            setAdditionalRecordingDuration(0);
            setRecordingStartTimestamp(Date.now());
            setIsRecording(true);
            startWalkaround();
            setTooltip(null);
        }
    }, [
        isRecording,
        getRecordingDurationMs,
        minRecordingDuration,
        walkaroundPosition,
        pauseRecording,
        onRecordingComplete,
    ]);
    var onDiscardDialogKeepRecording = (0, react_1.useCallback)(function () {
        resumeRecording();
        setDiscardDialogDisplayed(false);
    }, [resumeRecording]);
    var onDiscardDialogDiscardVideo = (0, react_1.useCallback)(function () {
        setIsRecordingPaused(false);
        setAdditionalRecordingDuration(0);
        setRecordingStartTimestamp(null);
        setIsRecording(false);
        setDiscardDialogDisplayed(false);
    }, []);
    (0, common_1.useInterval)(function () {
        if (isRecording) {
            onCaptureVideoFrame === null || onCaptureVideoFrame === void 0 ? void 0 : onCaptureVideoFrame();
        }
    }, isRecording ? screenshotInterval : null);
    (0, react_1.useEffect)(function () {
        if (isViolatingEnforcedOrientation && isRecording) {
            setOrientationPause(true);
            pauseRecording();
        }
        else if (!isViolatingEnforcedOrientation && orientationPause) {
            setOrientationPause(false);
            resumeRecording();
        }
    }, [isViolatingEnforcedOrientation, isRecording, orientationPause]);
    (0, react_1.useEffect)(function () {
        if (isRecording) {
            if (walkaroundPosition > 315) {
                setTooltip(VideoRecordingTooltip.END);
            }
            else {
                setTooltip(null);
            }
        }
    }, [walkaroundPosition, isRecording]);
    return {
        isRecordingPaused: isRecordingPaused,
        recordingDurationMs: getRecordingDurationMs(),
        onClickRecordVideo: onClickRecordVideo,
        onDiscardDialogKeepRecording: onDiscardDialogKeepRecording,
        onDiscardDialogDiscardVideo: onDiscardDialogDiscardVideo,
        isDiscardDialogDisplayed: isDiscardDialogDisplayed,
        pauseRecording: pauseRecording,
        resumeRecording: resumeRecording,
        tooltip: tooltip,
    };
}
exports.useVideoRecording = useVideoRecording;
