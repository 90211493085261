"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVehicleWalkaround = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
/**
 * Custom hook used to manage the vehicle walkaround tracking.
 */
function useVehicleWalkaround(_a) {
    var alpha = _a.alpha;
    var _b = (0, react_1.useState)(null), startingAlpha = _b[0], setStartingAlpha = _b[1];
    var _c = (0, react_1.useState)(45), checkpoint = _c[0], setCheckpoint = _c[1];
    var _d = (0, react_1.useState)(90), nextCheckpoint = _d[0], setNextCheckpoint = _d[1];
    var walkaroundPosition = (0, react_1.useMemo)(function () {
        if (!startingAlpha) {
            return 0;
        }
        var diff = startingAlpha - alpha;
        var position = diff < 0 ? 360 + diff : diff;
        var newWalkaroundPosition = position <= nextCheckpoint ? position : 0;
        if (nextCheckpoint === 405 && newWalkaroundPosition < 180) {
            return 359;
        }
        return newWalkaroundPosition;
    }, [startingAlpha, alpha, nextCheckpoint]);
    var startWalkaround = (0, react_1.useCallback)(function () {
        setStartingAlpha(alpha);
        setCheckpoint(45);
        setNextCheckpoint(90);
    }, [alpha]);
    (0, react_1.useEffect)(function () {
        if (walkaroundPosition >= checkpoint) {
            setCheckpoint(nextCheckpoint);
            setNextCheckpoint(function (value) { return value + 45; });
        }
    }, [walkaroundPosition, checkpoint, nextCheckpoint]);
    return (0, common_1.useObjectMemo)({ startWalkaround: startWalkaround, walkaroundPosition: walkaroundPosition });
}
exports.useVehicleWalkaround = useVehicleWalkaround;
