"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordVideoButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var RecordVideoButton_styles_1 = require("./RecordVideoButton.styles");
/**
 * Button used on the VideoCapture component, displayed on top of the camera preview to allow the user to record a
 * video.
 */
exports.RecordVideoButton = (0, react_1.forwardRef)(function RecordVideoButton(_a, ref) {
    var _b = _a.size, size = _b === void 0 ? 80 : _b, _c = _a.isRecording, isRecording = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, tooltip = _a.tooltip, _e = _a.tooltipPosition, tooltipPosition = _e === void 0 ? 'up' : _e, _f = _a.style, style = _f === void 0 ? {} : _f, onClick = _a.onClick, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, onMouseDown = _a.onMouseDown, onMouseUp = _a.onMouseUp, passThroughProps = __rest(_a, ["size", "isRecording", "disabled", "tooltip", "tooltipPosition", "style", "onClick", "onMouseEnter", "onMouseLeave", "onMouseDown", "onMouseUp"]);
    var _g = (0, common_1.useInteractiveStatus)({
        disabled: disabled,
        componentHandlers: {
            onMouseEnter: onMouseEnter,
            onMouseLeave: onMouseLeave,
            onMouseDown: onMouseDown,
            onMouseUp: onMouseUp,
        },
    }), status = _g.status, eventHandlers = _g.eventHandlers;
    var _h = (0, RecordVideoButton_styles_1.useRecordVideoButtonStyles)({
        size: size,
        isRecording: isRecording,
        status: status,
        tooltipPosition: tooltipPosition,
    }), container = _h.container, innerCircle = _h.innerCircle, tooltipContainer = _h.tooltipContainer, tooltipArrow = _h.tooltipArrow;
    return ((0, jsx_runtime_1.jsxs)("button", __assign({ ref: ref, style: __assign(__assign({}, container), style), onClick: onClick, disabled: disabled }, eventHandlers, passThroughProps, { "data-testid": 'record-video-button' }, { children: [tooltip && (0, jsx_runtime_1.jsx)("span", __assign({ style: tooltipContainer }, { children: tooltip })), tooltip && (0, jsx_runtime_1.jsx)("span", { style: tooltipArrow }), (0, jsx_runtime_1.jsx)("span", { style: innerCircle })] })));
});
