"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehiclePartSelection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var icons_1 = require("../../icons");
var VehicleDynamicWireframe_1 = require("../VehicleDynamicWireframe");
var VehiclePartSelection_style_1 = require("./VehiclePartSelection.style");
var ORIENTATIONS_ORDER = [
    types_1.PartSelectionOrientation.FRONT_LEFT,
    types_1.PartSelectionOrientation.REAR_LEFT,
    types_1.PartSelectionOrientation.REAR_RIGHT,
    types_1.PartSelectionOrientation.FRONT_RIGHT,
];
/**
 * Component that displays a rotatable vehicle wireframe that allows the user to select multiple vehicle parts.
 */
function VehiclePartSelection(_a) {
    var vehicleType = _a.vehicleType, _b = _a.orientation, initialOrientation = _b === void 0 ? types_1.PartSelectionOrientation.FRONT_LEFT : _b, _c = _a.onPartsSelected, onPartsSelected = _c === void 0 ? function () { } : _c, _d = _a.primaryColor, primaryColor = _d === void 0 ? 'primary-base' : _d, _e = _a.secondaryColor, secondaryColor = _e === void 0 ? 'text-primary' : _e, _f = _a.maxSelectableParts, maxSelectableParts = _f === void 0 ? Infinity : _f;
    var _g = (0, react_1.useState)(initialOrientation), orientation = _g[0], setOrientation = _g[1];
    var _h = (0, react_1.useState)([]), selectedParts = _h[0], setSelectedParts = _h[1];
    var utils = (0, common_1.useMonkTheme)().utils;
    var rotateRight = function () {
        var currentIndex = ORIENTATIONS_ORDER.indexOf(orientation);
        var nextIndex = (currentIndex + 1) % ORIENTATIONS_ORDER.length;
        setOrientation(ORIENTATIONS_ORDER[nextIndex]);
    };
    var rotateLeft = function () {
        var currentIndex = ORIENTATIONS_ORDER.indexOf(orientation);
        var nextIndex = (currentIndex - 1 + ORIENTATIONS_ORDER.length) % ORIENTATIONS_ORDER.length;
        setOrientation(ORIENTATIONS_ORDER[nextIndex]);
    };
    var togglePart = function (part) {
        var isSelected = selectedParts.includes(part);
        if (isSelected) {
            var newSelectedParts = selectedParts.filter(function (p) { return p !== part; });
            setSelectedParts(newSelectedParts);
            onPartsSelected(newSelectedParts);
        }
        else {
            var newSelectedParts = __spreadArray(__spreadArray([], selectedParts, true), [part], false);
            if (newSelectedParts.length > maxSelectableParts) {
                newSelectedParts = __spreadArray([], newSelectedParts.slice(1), true);
            }
            setSelectedParts(newSelectedParts);
            onPartsSelected(newSelectedParts);
        }
    };
    var getPartAttributes = function (part) {
        return {
            style: {
                fill: selectedParts.includes(part) ? utils.getColor(primaryColor) : undefined,
                stroke: utils.getColor(secondaryColor),
            },
        };
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: VehiclePartSelection_style_1.styles['wrapper'] }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: VehiclePartSelection_style_1.styles['leftArrowContainer'] }, { children: [(0, jsx_runtime_1.jsx)(icons_1.Icon, { style: VehiclePartSelection_style_1.styles['leftArrow'], icon: 'rotate-left', primaryColor: 'text-primary', onClick: rotateRight, size: VehiclePartSelection_style_1.ICON_SIZE }), (0, jsx_runtime_1.jsx)("span", { style: VehiclePartSelection_style_1.styles['spacer'] })] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: VehiclePartSelection_style_1.styles['wireframeContainer'] }, { children: (0, jsx_runtime_1.jsx)(VehicleDynamicWireframe_1.VehicleDynamicWireframe, { vehicleType: vehicleType, orientation: orientation, onClickPart: togglePart, getPartAttributes: getPartAttributes }) })), (0, jsx_runtime_1.jsx)(icons_1.Icon, { style: VehiclePartSelection_style_1.styles['rightArrow'], icon: 'rotate-right', primaryColor: 'text-primary', onClick: rotateLeft, size: VehiclePartSelection_style_1.ICON_SIZE })] })));
}
exports.VehiclePartSelection = VehiclePartSelection;
