"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoCaptureTutorial = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var VideoCapturePageLayout_1 = require("../../VideoCapturePageLayout");
/**
 * This component is a tutorial displayed on top of the camera when the user first starts the video capture.
 */
function VideoCaptureTutorial(_a) {
    var onClose = _a.onClose;
    var t = (0, react_i18next_1.useTranslation)().t;
    var confirmButtonProps = {
        onClick: onClose,
        children: t('video.tutorial.confirm'),
    };
    return ((0, jsx_runtime_1.jsxs)(VideoCapturePageLayout_1.VideoCapturePageLayout, __assign({ showBackdrop: true, confirmButtonProps: confirmButtonProps }, { children: [(0, jsx_runtime_1.jsx)(VideoCapturePageLayout_1.PageLayoutItem, { icon: 'car-arrow', title: t('video.tutorial.start.title'), description: t('video.tutorial.start.description') }), (0, jsx_runtime_1.jsx)(VideoCapturePageLayout_1.PageLayoutItem, { icon: '360', title: t('video.tutorial.finish.title'), description: t('video.tutorial.finish.description') }), (0, jsx_runtime_1.jsx)(VideoCapturePageLayout_1.PageLayoutItem, { icon: 'circle-dot', title: t('video.tutorial.photos.title'), description: t('video.tutorial.photos.description') })] })));
}
exports.VideoCaptureTutorial = VideoCaptureTutorial;
