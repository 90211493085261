"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHUDButtonsStyles = void 0;
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var HUDButtons_styles_1 = require("./HUDButtons.styles");
var ANIMATION_DELAY_MS = 50;
function useHUDButtonsStyles(params) {
    var _a = (0, react_1.useState)(false), backgroundAnimationStart = _a[0], setBackgroundAnimationStart = _a[1];
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var palette = (0, common_1.useMonkTheme)().palette;
    (0, common_1.useAsyncEffect)(function () {
        setBackgroundAnimationStart(true);
        return (0, common_1.timeoutPromise)(ANIMATION_DELAY_MS);
    }, [params.galleryPreview], {
        onResolve: function () { return setBackgroundAnimationStart(false); },
    });
    return {
        containerStyle: __assign(__assign(__assign({}, HUDButtons_styles_1.styles['container']), responsive(HUDButtons_styles_1.styles['containersPortrait'])), responsive(HUDButtons_styles_1.styles['containersLandscape'])),
        gallery: {
            style: __assign(__assign(__assign({}, HUDButtons_styles_1.styles['button']), { backgroundColor: HUDButtons_styles_1.captureButtonBackgroundColors[params.galleryStatus], borderColor: HUDButtons_styles_1.captureButtonForegroundColors[params.galleryStatus] }), (params.galleryStatus === types_1.InteractiveStatus.DISABLED ? HUDButtons_styles_1.styles['buttonDisabled'] : {})),
            iconColor: HUDButtons_styles_1.captureButtonForegroundColors[params.galleryStatus],
        },
        galleryBadgeStyle: __assign(__assign({}, HUDButtons_styles_1.styles['buttonBadge']), { backgroundColor: palette.alert.base, color: palette.text.primary, visibility: params.showGalleryBadge ? 'visible' : 'hidden' }),
        close: {
            style: __assign(__assign(__assign(__assign({}, HUDButtons_styles_1.styles['button']), { backgroundColor: HUDButtons_styles_1.captureButtonBackgroundColors[params.closeStatus], borderColor: HUDButtons_styles_1.captureButtonForegroundColors[params.closeStatus] }), (params.closeStatus === types_1.InteractiveStatus.DISABLED ? HUDButtons_styles_1.styles['buttonDisabled'] : {})), { visibility: params.showCloseButton ? 'visible' : 'hidden' }),
            iconColor: HUDButtons_styles_1.captureButtonForegroundColors[params.closeStatus],
        },
        backgroundCoverStyle: __assign(__assign({}, HUDButtons_styles_1.styles['backgroundCover']), { backgroundImage: params.galleryPreview ? "url(".concat(params.galleryPreview, ")") : 'none', transition: backgroundAnimationStart ? 'none' : 'transform 0.2s ease-out', transform: "scale(".concat(backgroundAnimationStart ? 0.3 : 1, ")") }),
    };
}
exports.useHUDButtonsStyles = useHUDButtonsStyles;
