"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVideoCaptureProcessingStyles = exports.styles = void 0;
var common_1 = require("@monkvision/common");
exports.styles = {
    container: {
        width: '100%',
        display: 'flex',
        alignSelf: 'stretch',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    labelContainer: {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px 8px 8px',
        fontSize: 14,
    },
    percentage: {
        fontFamily: 'monospace',
    },
    progressBarContainer: {
        width: '80%',
        borderRadius: 9999,
        borderStyle: 'solid',
        borderWidth: 1,
    },
    progressBar: {
        height: 10,
        borderRadius: 9999,
    },
    errorMessage: {
        padding: '0 16px',
        textAlign: 'center',
    },
};
function useVideoCaptureProcessingStyles(progress) {
    var palette = (0, common_1.useMonkTheme)().palette;
    return {
        containerStyle: __assign(__assign({}, exports.styles['container']), { color: palette.text.primary }),
        progressBarContainerStyle: __assign(__assign({}, exports.styles['progressBarContainer']), { borderColor: palette.primary.dark }),
        progressBarStyle: __assign(__assign({}, exports.styles['progressBar']), { backgroundColor: palette.primary.dark, width: "".concat(progress * 100, "%") }),
    };
}
exports.useVideoCaptureProcessingStyles = useVideoCaptureProcessingStyles;
