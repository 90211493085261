"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompression = void 0;
var monitoring_1 = require("@monkvision/monitoring");
var react_1 = require("react");
var monitoring_2 = require("../monitoring");
var utils_1 = require("./utils");
function startCompressionMeasurement(options, image, monitoring) {
    var _a;
    var _b, _c;
    (_b = monitoring === null || monitoring === void 0 ? void 0 : monitoring.transaction) === null || _b === void 0 ? void 0 : _b.startMeasurement(monitoring_2.CompressionMeasurement.operation, {
        data: monitoring === null || monitoring === void 0 ? void 0 : monitoring.data,
        tags: __assign((_a = {}, _a[monitoring_2.CompressionMeasurement.formatTagName] = options.format, _a[monitoring_2.CompressionMeasurement.qualityTagName] = options.quality, _a[monitoring_2.CompressionMeasurement.dimensionsTagName] = "".concat(image.width, "x").concat(image.height), _a), ((_c = monitoring === null || monitoring === void 0 ? void 0 : monitoring.tags) !== null && _c !== void 0 ? _c : {})),
        description: monitoring_2.CompressionMeasurement.description,
    });
}
function stopCompressionMeasurement(status, monitoring) {
    var _a;
    (_a = monitoring === null || monitoring === void 0 ? void 0 : monitoring.transaction) === null || _a === void 0 ? void 0 : _a.stopMeasurement(monitoring_2.CompressionMeasurement.operation, status);
}
function setCustomMeasurements(image, picture, monitoring) {
    var _a, _b;
    var imageSizeBytes = image.data.length;
    var pictureSizeBytes = picture.blob.size;
    (_a = monitoring === null || monitoring === void 0 ? void 0 : monitoring.transaction) === null || _a === void 0 ? void 0 : _a.setMeasurement(monitoring_2.CompressionSizeRatioMeasurement.name, pictureSizeBytes / imageSizeBytes, 'ratio');
    (_b = monitoring === null || monitoring === void 0 ? void 0 : monitoring.transaction) === null || _b === void 0 ? void 0 : _b.setMeasurement(monitoring_2.PictureSizeMeasurement.name, pictureSizeBytes, 'byte');
}
function compressUsingBrowser(image, canvasRef, options) {
    var _a = (0, utils_1.getCanvasHandle)(canvasRef), canvas = _a.canvas, context = _a.context;
    context.putImageData(image, 0, 0);
    return new Promise(function (resolve, reject) {
        canvas.toBlob(function (blob) {
            if (!blob) {
                reject(new Error('Unable to convert canvas to Blob, toBlob() returned null.'));
            }
            else {
                resolve({
                    blob: blob,
                    uri: URL.createObjectURL(blob),
                    mimetype: options.format,
                    width: image.width,
                    height: image.height,
                });
            }
        }, options.format, options.quality);
    });
}
/**
 * Custom hook used to manage the camera <canvas> element used to take video screenshots and encode images.
 */
function useCompression(_a) {
    var _this = this;
    var canvasRef = _a.canvasRef, options = _a.options;
    return (0, react_1.useCallback)(function (image, monitoring) { return __awaiter(_this, void 0, void 0, function () {
        var picture, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    startCompressionMeasurement(options, image, monitoring);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, compressUsingBrowser(image, canvasRef, options)];
                case 2:
                    picture = _a.sent();
                    setCustomMeasurements(image, picture, monitoring);
                    stopCompressionMeasurement(monitoring_1.TransactionStatus.OK, monitoring);
                    return [2 /*return*/, picture];
                case 3:
                    err_1 = _a.sent();
                    stopCompressionMeasurement(monitoring_1.TransactionStatus.UNKNOWN_ERROR, monitoring);
                    throw err_1;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [options.format, options.quality]);
}
exports.useCompression = useCompression;
