"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOrientationEnforcerStyles = exports.styles = void 0;
var common_1 = require("@monkvision/common");
exports.styles = {
    container: {
        position: 'fixed',
        inset: 0,
        zIndex: 999999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box',
        padding: '50px 10%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 18,
        marginLeft: 16,
    },
    description: {
        fontSize: 16,
        paddingTop: 16,
        opacity: 0.8,
        textAlign: 'center',
    },
};
function useOrientationEnforcerStyles() {
    var palette = (0, common_1.useMonkTheme)().palette;
    return {
        containerStyle: __assign(__assign({}, exports.styles['container']), { backgroundColor: palette.background.base }),
    };
}
exports.useOrientationEnforcerStyles = useOrientationEnforcerStyles;
