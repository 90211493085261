"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createdOneDamage = exports.isCreatedOneDamageAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a CreatedOneDamage while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isCreatedOneDamageAction(action) {
    return action.type === monkAction_1.MonkActionType.CREATED_ONE_DAMAGE;
}
exports.isCreatedOneDamageAction = isCreatedOneDamageAction;
/**
 * Reducer function for a createdOneDamage action.
 */
function createdOneDamage(state, action) {
    var damages = state.damages, inspections = state.inspections, parts = state.parts;
    var payload = action.payload;
    var inspection = inspections.find(function (value) { return value.id === payload.damage.inspectionId; });
    if (inspection) {
        inspection.damages = inspection.damages.filter(function (damageId) { return ![payload.damage.id, payload.localId].includes(damageId); });
        inspection.damages.push(payload.damage.id);
    }
    var newDamages = damages.filter(function (damage) { return ![payload.damage.id, payload.localId].includes(damage.id); });
    var partsRelated = payload.damage.parts
        .map(function (part) {
        var _a;
        return (_a = parts
            .filter(function (value) { return value.inspectionId === payload.damage.inspectionId; })
            .find(function (value) { return value.type === part; })) === null || _a === void 0 ? void 0 : _a.id;
    })
        .filter(function (v) { return v !== undefined; });
    var newParts = parts.map(function (part) {
        if (partsRelated.includes(part.id)) {
            var damageIds = part.damages.filter(function (damageId) { return ![payload.damage.id, payload.localId].includes(damageId); });
            return __assign(__assign({}, part), { damages: __spreadArray(__spreadArray([], damageIds, true), [payload.damage.id], false) });
        }
        return part;
    });
    newDamages.push(__assign(__assign({}, payload.damage), { parts: partsRelated }));
    return __assign(__assign({}, state), { parts: newParts, damages: newDamages, inspections: __spreadArray([], inspections, true) });
}
exports.createdOneDamage = createdOneDamage;
