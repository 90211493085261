"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRecordVideoButtonStyles = exports.styles = exports.RECORD_VIDEO_BUTTON_RECORDING_COLORS = void 0;
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var TakePictureButton_styles_1 = require("../TakePictureButton/TakePictureButton.styles");
exports.RECORD_VIDEO_BUTTON_RECORDING_COLORS = (0, common_1.getInteractiveVariants)('#cb0000', common_1.InteractiveVariation.DARKEN);
var BORDER_WIDTH_RATIO = 0.05;
var INNER_CIRCLE_DEFAULT_RATIO = 0.5;
var INNER_CIRCLE_SMALL_RATIO = 0.3;
var INNER_CIRCLE_BIG_RATIO = 0.7;
var TOOLTIP_MAX_WIDTH_RATIO = 2;
var TOOLTIP_ARROW_RATIO = 0.15;
var TOOLTIP_MARGIN_RATIO = 0.1;
var RECORDING_ANIMATION_DURATION_MS = 1200;
exports.styles = {
    button: {
        position: 'relative',
        borderStyle: 'solid',
        borderRadius: '50%',
        cursor: 'pointer',
        boxSizing: 'border-box',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin: 0,
    },
    buttonDisabled: {
        opacity: 0.75,
        cursor: 'default',
    },
    innerCircle: {
        borderRadius: '50%',
    },
    tooltip: {
        position: 'absolute',
        zIndex: 2,
        pointerEvents: 'none',
        padding: '10px 6px',
        borderRadius: 5,
        fontSize: 13,
        textAlign: 'center',
        width: 'max-content',
    },
    tooltipArrow: {
        position: 'absolute',
        zIndex: 2,
        pointerEvents: 'none',
        borderStyle: 'solid',
    },
};
function getTooltipPosition(position, size, tooltipBg) {
    switch (position) {
        case 'up':
            return {
                tooltip: {
                    top: -size * (BORDER_WIDTH_RATIO + TOOLTIP_ARROW_RATIO + TOOLTIP_MARGIN_RATIO),
                    left: -(size * BORDER_WIDTH_RATIO),
                    transform: "translateX(calc(".concat(size / 2, "px - 50%)) translateY(-100%)"),
                },
                arrow: {
                    top: -size * (BORDER_WIDTH_RATIO + TOOLTIP_ARROW_RATIO + TOOLTIP_MARGIN_RATIO),
                    borderWidth: size * TOOLTIP_ARROW_RATIO,
                    borderColor: "".concat(tooltipBg, " transparent transparent transparent"),
                },
            };
        case 'down':
            return {
                tooltip: {
                    top: size * (1 - BORDER_WIDTH_RATIO + TOOLTIP_ARROW_RATIO + TOOLTIP_MARGIN_RATIO),
                    left: -(size * BORDER_WIDTH_RATIO),
                    transform: "translateX(calc(".concat(size / 2, "px - 50%))"),
                },
                arrow: {
                    top: size * (1 - 2 * BORDER_WIDTH_RATIO),
                    borderWidth: size * TOOLTIP_ARROW_RATIO,
                    borderColor: "transparent transparent ".concat(tooltipBg, " transparent"),
                },
            };
        case 'left':
            return {
                tooltip: {
                    top: -(size * BORDER_WIDTH_RATIO),
                    left: -size * (BORDER_WIDTH_RATIO + TOOLTIP_ARROW_RATIO + TOOLTIP_MARGIN_RATIO),
                    transform: "translateX(-100%) translateY(calc(".concat(size / 2, "px - 50%))"),
                },
                arrow: {
                    left: -size * (BORDER_WIDTH_RATIO + TOOLTIP_ARROW_RATIO + TOOLTIP_MARGIN_RATIO),
                    borderWidth: size * TOOLTIP_ARROW_RATIO,
                    borderColor: "transparent transparent transparent ".concat(tooltipBg),
                },
            };
        case 'right':
            return {
                tooltip: {
                    top: -(size * BORDER_WIDTH_RATIO),
                    left: size * (1 - BORDER_WIDTH_RATIO + TOOLTIP_ARROW_RATIO + TOOLTIP_MARGIN_RATIO),
                    transform: "translateY(calc(".concat(size / 2, "px - 50%))"),
                },
                arrow: {
                    left: size * (1 - 2 * BORDER_WIDTH_RATIO),
                    borderWidth: size * TOOLTIP_ARROW_RATIO,
                    borderColor: "transparent ".concat(tooltipBg, " transparent transparent"),
                },
            };
        default:
            return { tooltip: {}, arrow: {} };
    }
}
function useRecordVideoButtonStyles(_a) {
    var size = _a.size, isRecording = _a.isRecording, status = _a.status, tooltipPosition = _a.tooltipPosition;
    var palette = (0, common_1.useMonkTheme)().palette;
    var _b = (0, react_1.useState)(INNER_CIRCLE_SMALL_RATIO), animationRatio = _b[0], setAnimationRatio = _b[1];
    var isMounted = (0, common_1.useIsMounted)();
    (0, common_1.useInterval)(function () {
        if (isMounted()) {
            setAnimationRatio(function (value) {
                return value === INNER_CIRCLE_SMALL_RATIO ? INNER_CIRCLE_BIG_RATIO : INNER_CIRCLE_SMALL_RATIO;
            });
        }
    }, isRecording ? RECORDING_ANIMATION_DURATION_MS : null);
    var colors = (0, react_1.useMemo)(function () { return ({
        tooltipBg: (0, common_1.changeAlpha)(palette.surface.dark, 0.5),
    }); }, []);
    var innerCircleSize = (isRecording ? animationRatio : INNER_CIRCLE_DEFAULT_RATIO) * size;
    var innerCircleBackgroundColor = isRecording
        ? exports.RECORD_VIDEO_BUTTON_RECORDING_COLORS[status]
        : TakePictureButton_styles_1.TAKE_PICTURE_BUTTON_COLORS[status];
    var position = getTooltipPosition(tooltipPosition, size, colors.tooltipBg);
    return {
        container: __assign(__assign(__assign({}, exports.styles['button']), (status === types_1.InteractiveStatus.DISABLED ? exports.styles['buttonDisabled'] : {})), { borderWidth: size * BORDER_WIDTH_RATIO, borderColor: TakePictureButton_styles_1.TAKE_PICTURE_BUTTON_COLORS[status], width: size, height: size }),
        innerCircle: __assign(__assign({}, exports.styles['innerCircle']), { backgroundColor: innerCircleBackgroundColor, width: innerCircleSize, height: innerCircleSize, transition: isRecording
                ? "width ".concat(RECORDING_ANIMATION_DURATION_MS, "ms linear, height ").concat(RECORDING_ANIMATION_DURATION_MS, "ms linear")
                : '' }),
        tooltipContainer: __assign(__assign(__assign({}, exports.styles['tooltip']), position.tooltip), { backgroundColor: colors.tooltipBg, color: palette.surface.light, maxWidth: size * TOOLTIP_MAX_WIDTH_RATIO }),
        tooltipArrow: __assign(__assign({}, exports.styles['tooltipArrow']), position.arrow),
    };
}
exports.useRecordVideoButtonStyles = useRecordVideoButtonStyles;
