"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackdropDialog = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var hooks_1 = require("./hooks");
var BackdropDialog_styles_1 = require("./BackdropDialog.styles");
var Button_1 = require("../Button");
var icons_1 = require("../../icons");
/**
 * This component can be used to display a fixed dialog on the screen, with a backdrop behind it. You can either pass a
 * custom component for the dialog modal, or use the default one and simply pass the text content of the dialog.
 */
function BackdropDialog(_a) {
    var _b = _a.show, show = _b === void 0 ? false : _b, _c = _a.backdropOpacity, backdropOpacity = _c === void 0 ? 0.7 : _c, _d = _a.showCancelButton, showCancelButton = _d === void 0 ? true : _d, dialogIcon = _a.dialogIcon, dialogIconPrimaryColor = _a.dialogIconPrimaryColor, _e = _a.message, message = _e === void 0 ? '' : _e, _f = _a.confirmLabel, confirmLabel = _f === void 0 ? '' : _f, _g = _a.cancelLabel, cancelLabel = _g === void 0 ? '' : _g, confirmIcon = _a.confirmIcon, cancelIcon = _a.cancelIcon, onConfirm = _a.onConfirm, onCancel = _a.onCancel, dialog = _a.dialog;
    var style = (0, hooks_1.useBackdropDialogStyles)({
        backdropOpacity: backdropOpacity,
        showCancelButton: showCancelButton,
        dialogIcon: dialogIcon,
    });
    return show ? ((0, jsx_runtime_1.jsx)("div", __assign({ style: style.backdrop, "data-testid": 'backdrop' }, { children: dialog !== null && dialog !== void 0 ? dialog : ((0, jsx_runtime_1.jsxs)("div", __assign({ style: style.dialog }, { children: [dialogIcon && ((0, jsx_runtime_1.jsx)(icons_1.Icon, { icon: dialogIcon, size: 80, primaryColor: dialogIconPrimaryColor, style: BackdropDialog_styles_1.styles['dialogIcon'] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: BackdropDialog_styles_1.styles['message'] }, { children: message })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: BackdropDialog_styles_1.styles['buttonsContainer'] }, { children: [(0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ variant: 'outline', primaryColor: 'primary-xlight', secondaryColor: 'background-dark', icon: confirmIcon, style: style.confirmButton, onClick: onConfirm }, { children: confirmLabel })), (0, jsx_runtime_1.jsx)(Button_1.Button, __assign({ variant: 'outline', primaryColor: 'alert-light', secondaryColor: 'background-dark', icon: cancelIcon, style: style.cancelButton, onClick: onCancel }, { children: cancelLabel }))] }))] }))) }))) : null;
}
exports.BackdropDialog = BackdropDialog;
