"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCameraScreenshot = void 0;
var react_1 = require("react");
var monitoring_1 = require("@monkvision/monitoring");
var monitoring_2 = require("../monitoring");
var utils_1 = require("./utils");
function startScreenshotMeasurement(dimensions, monitoring) {
    var _a;
    var _b, _c;
    (_b = monitoring === null || monitoring === void 0 ? void 0 : monitoring.transaction) === null || _b === void 0 ? void 0 : _b.startMeasurement(monitoring_2.ScreenshotMeasurement.operation, {
        data: monitoring.data,
        tags: __assign((_a = {}, _a[monitoring_2.ScreenshotMeasurement.outputResolutionTagName] = dimensions
            ? "".concat(dimensions.width, "x").concat(dimensions.height)
            : 'null', _a), ((_c = monitoring.tags) !== null && _c !== void 0 ? _c : {})),
        description: monitoring_2.ScreenshotMeasurement.description,
    });
}
function stopScreenshotMeasurement(status, monitoring) {
    var _a;
    (_a = monitoring === null || monitoring === void 0 ? void 0 : monitoring.transaction) === null || _a === void 0 ? void 0 : _a.stopMeasurement(monitoring_2.ScreenshotMeasurement.operation, status);
}
function setScreeshotSizeMeasurement(image, monitoring) {
    var _a;
    var imageSizeBytes = image.data.length;
    (_a = monitoring === null || monitoring === void 0 ? void 0 : monitoring.transaction) === null || _a === void 0 ? void 0 : _a.setMeasurement(monitoring_2.ScreenshotSizeMeasurement.name, imageSizeBytes, 'byte');
}
/**
 * Custom hook used to take screenshots of a video element. This hook is aimed to be used in pair with a `<canvas>`
 * element referenced by the returned ref.
 */
function useCameraScreenshot(_a) {
    var videoRef = _a.videoRef, canvasRef = _a.canvasRef, dimensions = _a.dimensions;
    return (0, react_1.useCallback)(function (monitoring) {
        startScreenshotMeasurement(dimensions, monitoring);
        var context = (0, utils_1.getCanvasHandle)(canvasRef, function () {
            return stopScreenshotMeasurement(monitoring_1.TransactionStatus.UNKNOWN_ERROR, monitoring);
        }).context;
        if (!dimensions) {
            stopScreenshotMeasurement(monitoring_1.TransactionStatus.UNKNOWN_ERROR, monitoring);
            throw new Error('Unable to take a picture because the video stream has no dimension.');
        }
        if (!videoRef.current) {
            stopScreenshotMeasurement(monitoring_1.TransactionStatus.UNKNOWN_ERROR, monitoring);
            throw new Error('Unable to take a picture because the video element is null.');
        }
        context.drawImage(videoRef.current, 0, 0, dimensions.width, dimensions.height);
        var imageData = context.getImageData(0, 0, dimensions.width, dimensions.height);
        setScreeshotSizeMeasurement(imageData, monitoring);
        stopScreenshotMeasurement(monitoring_1.TransactionStatus.OK, monitoring);
        return imageData;
    }, [dimensions]);
}
exports.useCameraScreenshot = useCameraScreenshot;
