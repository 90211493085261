"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iconNames = void 0;
/**
 * An array containing every available icon name.
 */
exports.iconNames = [
    '360',
    'account-circle',
    'acv',
    'add-circle',
    'add-comment',
    'add-image',
    'add-photo',
    'add',
    'align-left',
    'align-right',
    'announcement',
    'arrow-back-ios',
    'arrow-back',
    'arrow-down',
    'arrow-drop-down',
    'arrow-drop-up',
    'arrow-forward-ios',
    'arrow-forward',
    'arrow-up',
    'attach-money',
    'auto-awesome',
    'bar-chart',
    'bell-graph',
    'bluetooth-searching',
    'bluetooth',
    'bookmark-outline',
    'bookmark',
    'calendar-today',
    'calendar',
    'camera-off',
    'camera',
    'camera-outline',
    'cancel',
    'car-arrow',
    'cellular-signal-no-connection',
    'check-circle-outline',
    'check-circle',
    'check',
    'chevron-left',
    'chevron-right',
    'circle',
    'circle-dot',
    'close',
    'cloud-download',
    'cloud-upload',
    'compass-outline',
    'content-cut',
    'convertible',
    'copy',
    'coupe',
    'credit-card',
    'credit-score',
    'crop',
    'cuv',
    'delete',
    'diamond',
    'directions-car',
    'double-arrow-down',
    'double-arrow-right',
    'double-arrow-up',
    'drag-handle',
    'edit',
    'emoji-events',
    'error-outline',
    'error',
    'expand-less',
    'expand-more',
    'facebook',
    'favorite-outline',
    'favorite',
    'file-download',
    'filter-list',
    'flash-off',
    'flashlight-off',
    'flashlight-on',
    'full-screen',
    'gallery',
    'gavel',
    'grid-off',
    'grid-on',
    'grid',
    'hatchback',
    'help-outline',
    'help',
    'history',
    'image',
    'info',
    'instagram',
    'keyboard',
    'lightbulb',
    'limo',
    'linkedin',
    'location-on',
    'location',
    'lock-open',
    'lock',
    'mail',
    'menu',
    'merge',
    'mic',
    'minivan',
    'more-horizontal',
    'more-vertical',
    'multiline-chart',
    'notifications-outline',
    'notifications',
    'odometer',
    'off-road',
    'offer-sent',
    'open-in-new',
    'paid',
    'pause',
    'person-outline',
    'play-arrow',
    'podcast',
    'price-check',
    'print',
    'priority-high',
    'processing',
    'progress',
    'public',
    'qr-code-scanner',
    'redo',
    'refresh',
    'remove-circle',
    'remove',
    'reply',
    'report-problem',
    'roadster',
    'robot',
    'rotate',
    'scan',
    'search',
    'sedan',
    'selected',
    'sell',
    'settings-outline',
    'settings',
    'share-ios',
    'share',
    'show-chart',
    'sort',
    'store',
    'suv',
    'sync-problem',
    'text-snippet',
    'timer',
    'trending-up',
    'truck',
    'tune',
    'twitter',
    'undo',
    'unfold-more',
    'van',
    'view-agenda',
    'visibility-off',
    'visibility-on',
    'volume-off',
    'volume-on',
    'wagon',
    'warning-outline',
    'warning',
    'wifi-off',
    'wifi',
    'zoom-in',
    'zoom-out',
    'rotate-left',
    'rotate-right',
];
