"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsMounted = void 0;
var react_1 = require("react");
/**
 * Custom hook returning a ref to a util function returning `true` if the component using the hook is mounted, and false
 * otherwise.
 */
function useIsMounted() {
    var isMounted = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(function () {
        isMounted.current = true;
        return function () {
            isMounted.current = false;
        };
    }, []);
    return (0, react_1.useCallback)(function () { return isMounted.current; }, []);
}
exports.useIsMounted = useIsMounted;
