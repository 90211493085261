"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoCapturePageLayout = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var react_i18next_1 = require("react-i18next");
var logos_asset_1 = require("../../assets/logos.asset");
var VideoCapturePageLayout_styles_1 = require("./VideoCapturePageLayout.styles");
/**
 * This component is used to display the same layout for every "default" screen for the VideoCapture process (the
 * premissions screen, the tutorial etc.).
 */
function VideoCapturePageLayout(_a) {
    var _b = _a.showBackdrop, showBackdrop = _b === void 0 ? false : _b, _c = _a.showTitle, showTitle = _c === void 0 ? true : _c, confirmButtonProps = _a.confirmButtonProps, children = _a.children;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _d = (0, VideoCapturePageLayout_styles_1.useVideoCapturePageLayoutStyles)({
        showBackdrop: showBackdrop,
    }), logoProps = _d.logoProps, containerStyle = _d.containerStyle, titleStyle = _d.titleStyle;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: containerStyle }, { children: [(0, jsx_runtime_1.jsx)(common_ui_web_1.DynamicSVG, __assign({ svg: logos_asset_1.monkLogoSVG }, logoProps)), showTitle && (0, jsx_runtime_1.jsx)("div", __assign({ style: titleStyle }, { children: t('video.introduction.title') })), (0, jsx_runtime_1.jsx)("div", __assign({ style: VideoCapturePageLayout_styles_1.styles['childrenContainer'] }, { children: children })), (0, jsx_runtime_1.jsx)("div", __assign({ style: VideoCapturePageLayout_styles_1.styles['confirmContainer'] }, { children: (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({}, (confirmButtonProps !== null && confirmButtonProps !== void 0 ? confirmButtonProps : {}))) }))] })));
}
exports.VideoCapturePageLayout = VideoCapturePageLayout;
