"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCounterLabel = void 0;
var react_i18next_1 = require("react-i18next");
var types_1 = require("../../types");
function useCounterLabel(props) {
    var t = (0, react_i18next_1.useTranslation)().t;
    if (props.mode === types_1.CaptureMode.SIGHT) {
        return "".concat(props.sightsTaken, " / ").concat(props.totalSights);
    }
    if (props.mode === types_1.CaptureMode.ADD_DAMAGE_1ST_SHOT) {
        return t('photo.hud.addDamage.damagedPartCounter');
    }
    return t('photo.hud.addDamage.closeupPictureCounter');
}
exports.useCounterLabel = useCounterLabel;
