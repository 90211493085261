"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DamageDisclosure = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var analytics_1 = require("@monkvision/analytics");
var camera_web_1 = require("@monkvision/camera-web");
var common_1 = require("@monkvision/common");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var types_1 = require("@monkvision/types");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var DamageDisclosure_styles_1 = require("./DamageDisclosure.styles");
var DamageDisclosureHUD_1 = require("./DamageDisclosureHUD");
var hooks_1 = require("./hooks");
var hooks_2 = require("../hooks");
var types_2 = require("../types");
// No ts-doc for this component : the component exported is DamageDisclosureHOC
function DamageDisclosure(_a) {
    var inspectionId = _a.inspectionId, apiConfig = _a.apiConfig, onClose = _a.onClose, onComplete = _a.onComplete, onPictureTaken = _a.onPictureTaken, _b = _a.enableCompliance, enableCompliance = _b === void 0 ? true : _b, complianceIssues = _a.complianceIssues, customComplianceThresholds = _a.customComplianceThresholds, _c = _a.useLiveCompliance, useLiveCompliance = _c === void 0 ? false : _c, _d = _a.maxUploadDurationWarning, maxUploadDurationWarning = _d === void 0 ? 15000 : _d, _e = _a.showCloseButton, showCloseButton = _e === void 0 ? false : _e, _f = _a.addDamage, addDamage = _f === void 0 ? types_1.AddDamage.PART_SELECT : _f, _g = _a.useAdaptiveImageQuality, useAdaptiveImageQuality = _g === void 0 ? true : _g, lang = _a.lang, enforceOrientation = _a.enforceOrientation, _h = _a.vehicleType, vehicleType = _h === void 0 ? types_1.VehicleType.SEDAN : _h, initialCameraConfig = __rest(_a, ["inspectionId", "apiConfig", "onClose", "onComplete", "onPictureTaken", "enableCompliance", "complianceIssues", "customComplianceThresholds", "useLiveCompliance", "maxUploadDurationWarning", "showCloseButton", "addDamage", "useAdaptiveImageQuality", "lang", "enforceOrientation", "vehicleType"]);
    (0, common_1.useI18nSync)(lang);
    var complianceOptions = (0, common_1.useObjectMemo)({
        enableCompliance: enableCompliance,
        complianceIssues: complianceIssues,
        useLiveCompliance: useLiveCompliance,
        customComplianceThresholds: customComplianceThresholds,
    });
    var t = (0, react_i18next_1.useTranslation)().t;
    var _j = (0, react_1.useState)(types_2.CaptureScreen.CAMERA), currentScreen = _j[0], setCurrentScreen = _j[1];
    var analytics = (0, analytics_1.useAnalytics)();
    var loading = (0, common_1.useLoadingState)();
    var handleOpenGallery = function () {
        setCurrentScreen(types_2.CaptureScreen.GALLERY);
        analytics.trackEvent('Gallery Opened');
    };
    var addDamageHandle = (0, hooks_2.useAddDamageMode)({
        addDamage: addDamage,
        currentScreen: currentScreen,
        damageDisclosure: true,
        handleOpenGallery: handleOpenGallery,
    });
    var disclosureState = (0, hooks_1.useDamageDisclosureState)({
        inspectionId: inspectionId,
        apiConfig: apiConfig,
        loading: loading,
        complianceOptions: complianceOptions,
    });
    (0, hooks_2.useTracking)({ inspectionId: inspectionId, authToken: apiConfig.authToken });
    var _k = (0, hooks_2.useAdaptiveCameraConfig)({
        initialCameraConfig: initialCameraConfig,
        useAdaptiveImageQuality: useAdaptiveImageQuality,
    }), adaptiveCameraConfig = _k.adaptiveCameraConfig, adaptiveUploadEventHandlers = _k.uploadEventHandlers;
    var _l = (0, hooks_2.useBadConnectionWarning)({ maxUploadDurationWarning: maxUploadDurationWarning }), isBadConnectionWarningDialogDisplayed = _l.isBadConnectionWarningDialogDisplayed, closeBadConnectionWarningDialog = _l.closeBadConnectionWarningDialog, badConnectionWarningUploadEventHandlers = _l.uploadEventHandlers;
    var uploadQueue = (0, hooks_2.useUploadQueue)({
        inspectionId: inspectionId,
        apiConfig: apiConfig,
        complianceOptions: complianceOptions,
        eventHandlers: [adaptiveUploadEventHandlers, badConnectionWarningUploadEventHandlers],
    });
    var images = (0, hooks_2.usePhotoCaptureImages)(inspectionId);
    var handlePictureTaken = (0, hooks_2.usePictureTaken)({
        captureState: disclosureState,
        addDamageHandle: addDamageHandle,
        uploadQueue: uploadQueue,
        onPictureTaken: onPictureTaken,
    });
    var handleGalleryBack = function () {
        setCurrentScreen(types_2.CaptureScreen.CAMERA);
    };
    var hudProps = {
        inspectionId: inspectionId,
        mode: addDamageHandle.mode,
        vehicleParts: addDamageHandle.vehicleParts,
        lastPictureTakenUri: disclosureState.lastPictureTakenUri,
        onOpenGallery: handleOpenGallery,
        onAddDamage: addDamageHandle.handleAddDamage,
        onAddDamagePartsSelected: addDamageHandle.handleAddDamagePartsSelected,
        onCancelAddDamage: addDamageHandle.handleCancelAddDamage,
        onRetry: disclosureState.retryLoadingInspection,
        loading: loading,
        onClose: onClose,
        showCloseButton: showCloseButton,
        images: images,
        addDamage: addDamage,
        onValidateVehicleParts: addDamageHandle.handleValidateVehicleParts,
        vehicleType: vehicleType,
        enforceOrientation: enforceOrientation,
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: DamageDisclosure_styles_1.styles['container'] }, { children: [currentScreen === types_2.CaptureScreen.CAMERA && ((0, jsx_runtime_1.jsx)(camera_web_1.Camera, __assign({ HUDComponent: DamageDisclosureHUD_1.DamageDisclosureHUD, onPictureTaken: handlePictureTaken, hudProps: hudProps }, adaptiveCameraConfig))), currentScreen === types_2.CaptureScreen.GALLERY && ((0, jsx_runtime_1.jsx)(common_ui_web_1.InspectionGallery, { inspectionId: inspectionId, sights: [], apiConfig: apiConfig, captureMode: true, lang: lang, showBackButton: true, onBack: handleGalleryBack, onNavigateToCapture: handleGalleryBack, onValidate: onComplete, addDamage: addDamage, validateButtonLabel: t('photo.gallery.next'), isInspectionCompleted: false, filterByImageType: types_1.ImageType.CLOSE_UP })), (0, jsx_runtime_1.jsx)(common_ui_web_1.BackdropDialog, { show: isBadConnectionWarningDialogDisplayed, showCancelButton: false, dialogIcon: 'warning-outline', dialogIconPrimaryColor: 'caution-base', message: t('photo.badConnectionWarning.message'), confirmLabel: t('photo.badConnectionWarning.confirm'), onConfirm: closeBadConnectionWarningDialog })] })));
}
exports.DamageDisclosure = DamageDisclosure;
