"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Camera = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var types_1 = require("@monkvision/types");
var common_1 = require("@monkvision/common");
var hooks_1 = require("./hooks");
var Camera_styles_1 = require("./Camera.styles");
/**
 * Component used in MonkJs project used to :
 * - Display a camera preview on the screen
 * - Display a given camera HUD (buttons) on top of the camera preview
 * - Provide tools to control the camera (take picture, compress images etc...)
 *
 * Please refer to the official documentation of the @monkvision/camera-web package (available
 * [here](https://github.com/monkvision/monkjs/blob/main/packages/camera-web/README.md)) for more details on how this
 * component works.
 */
function Camera(_a) {
    var _b = _a.resolution, resolution = _b === void 0 ? types_1.CameraResolution.UHD_4K : _b, _c = _a.format, format = _c === void 0 ? types_1.CompressionFormat.JPEG : _c, _d = _a.quality, quality = _d === void 0 ? 0.6 : _d, _e = _a.allowImageUpscaling, allowImageUpscaling = _e === void 0 ? false : _e, HUDComponent = _a.HUDComponent, hudProps = _a.hudProps, monitoring = _a.monitoring, onPictureTaken = _a.onPictureTaken;
    var previewResolution = (0, react_1.useMemo)(function () { return ((0, common_1.isMobileDevice)() ? types_1.CameraResolution.UHD_4K : types_1.CameraResolution.FHD_1080P); }, []);
    var _f = (0, hooks_1.useCameraPreview)({
        resolution: previewResolution,
        facingMode: hooks_1.CameraFacingMode.ENVIRONMENT,
    }), videoRef = _f.ref, streamDimensions = _f.dimensions, previewDimensions = _f.previewDimensions, error = _f.error, retry = _f.retry, isPreviewLoading = _f.isLoading, availableCameraDevices = _f.availableCameraDevices, selectedCameraDeviceId = _f.selectedCameraDeviceId;
    var _g = (0, hooks_1.useCameraCanvas)({
        resolution: resolution,
        streamDimensions: streamDimensions,
        allowImageUpscaling: allowImageUpscaling,
    }), canvasRef = _g.ref, canvasDimensions = _g.dimensions;
    var takeScreenshot = (0, hooks_1.useCameraScreenshot)({
        videoRef: videoRef,
        canvasRef: canvasRef,
        dimensions: canvasDimensions,
    });
    var compress = (0, hooks_1.useCompression)({ canvasRef: canvasRef, options: { format: format, quality: quality } });
    var _h = (0, hooks_1.useTakePicture)({
        compress: compress,
        takeScreenshot: takeScreenshot,
        onPictureTaken: onPictureTaken,
        monitoring: monitoring,
        availableCameraDevices: availableCameraDevices,
        selectedCameraDeviceId: selectedCameraDeviceId,
    }), takePicture = _h.takePicture, isTakePictureLoading = _h.isLoading;
    var isLoading = isPreviewLoading || isTakePictureLoading;
    var cameraPreview = (0, react_1.useMemo)(function () { return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: Camera_styles_1.styles['container'] }, { children: [(0, jsx_runtime_1.jsx)("video", { style: Camera_styles_1.styles['cameraPreview'], ref: videoRef, autoPlay: true, playsInline: true, controls: false, muted: true, "data-testid": 'camera-video-preview' }), (0, jsx_runtime_1.jsx)("canvas", { ref: canvasRef, style: Camera_styles_1.styles['cameraCanvas'], "data-testid": 'camera-canvas' })] }))); }, []);
    var getImageData = (0, react_1.useCallback)(function () { return takeScreenshot(); }, [takeScreenshot]);
    var compressImage = (0, react_1.useCallback)(function (image) { return compress(image); }, [compress]);
    return HUDComponent ? ((0, jsx_runtime_1.jsx)(HUDComponent, __assign({ handle: {
            takePicture: takePicture,
            getImageData: getImageData,
            compressImage: compressImage,
            error: error,
            retry: retry,
            isLoading: isLoading,
            dimensions: streamDimensions,
            previewDimensions: previewDimensions,
        }, cameraPreview: cameraPreview }, (hudProps !== null && hudProps !== void 0 ? hudProps : {})))) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: cameraPreview }));
}
exports.Camera = Camera;
