"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVideoCaptureRecordingStyles = exports.styles = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
exports.styles = {
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
    },
    containerLandscape: {
        __media: { landscape: true },
        flexDirection: 'row',
    },
    indicators: {
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        flexDirection: 'row',
        padding: 20,
    },
    indicatorsLandscape: {
        __media: { landscape: true },
        justifyContent: 'start',
        flexDirection: 'column',
    },
    recordingDuration: {
        padding: 10,
        borderRadius: 9999,
    },
    controls: {
        alignSelf: 'stretch',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '0 20px 32px 20px',
    },
    controlsLandscape: {
        __media: { landscape: true },
        flexDirection: 'column-reverse',
        padding: '20px 32px 20px 0',
    },
    walkaroundIndicatorDisabled: {
        opacity: 0.7,
        filter: 'grayscale(1)',
    },
    takePictureFlash: {
        position: 'fixed',
        inset: '0 0 0 0',
        zIndex: 999,
        backgroundColor: '#efefef',
        opacity: 0,
        transition: 'opacity 0.5s ease-out',
        pointerEvents: 'none',
    },
    takePictureFlashVisible: {
        opacity: 1,
        transition: 'none',
    },
};
function useVideoCaptureRecordingStyles(_a) {
    var isRecording = _a.isRecording;
    var _b = (0, react_1.useState)(false), isTakePictureFlashVisible = _b[0], setTakePictureFlashVisible = _b[1];
    var palette = (0, common_1.useMonkTheme)().palette;
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var isPortrait = (0, common_1.useWindowDimensions)().isPortrait;
    var isMounted = (0, common_1.useIsMounted)();
    var showTakePictureFlash = function () {
        setTakePictureFlashVisible(true);
        setTimeout(function () {
            if (isMounted()) {
                setTakePictureFlashVisible(false);
            }
        }, 100);
    };
    return {
        container: __assign(__assign({}, exports.styles['container']), responsive(exports.styles['containerLandscape'])),
        indicators: __assign(__assign({}, exports.styles['indicators']), responsive(exports.styles['indicatorsLandscape'])),
        recordingDuration: __assign(__assign({}, exports.styles['recordingDuration']), { color: palette.text.primary, backgroundColor: palette.alert.base }),
        controls: __assign(__assign({}, exports.styles['controls']), responsive(exports.styles['controlsLandscape'])),
        takePictureFlash: __assign(__assign({}, exports.styles['takePictureFlash']), (isTakePictureFlashVisible ? exports.styles['takePictureFlashVisible'] : {})),
        walkaroundIndicator: __assign({}, (isRecording ? {} : exports.styles['walkaroundIndicatorDisabled'])),
        showTakePictureFlash: showTakePictureFlash,
        tooltipPosition: (isPortrait ? 'up' : 'left'),
    };
}
exports.useVideoCaptureRecordingStyles = useVideoCaptureRecordingStyles;
