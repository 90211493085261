"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDElements = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var PhotoCaptureHUDElementsSight_1 = require("../PhotoCaptureHUDElementsSight");
var components_1 = require("../../../components");
var types_1 = require("../../../types");
/**
 * Component implementing an HUD displayed on top of the Camera preview during the PhotoCapture process.
 */
function PhotoCaptureHUDElements(params) {
    if (params.isLoading || !!params.error) {
        return null;
    }
    if (params.mode === types_1.CaptureMode.SIGHT) {
        return ((0, jsx_runtime_1.jsx)(PhotoCaptureHUDElementsSight_1.PhotoCaptureHUDElementsSight, { sights: params.sights, selectedSight: params.selectedSight, onSelectedSight: params.onSelectSight, onRetakeSight: params.onRetakeSight, sightsTaken: params.sightsTaken, onAddDamage: params.onAddDamage, previewDimensions: params.previewDimensions, images: params.images, addDamage: params.addDamage, sightGuidelines: params.sightGuidelines, enableSightGuidelines: params.enableSightGuidelines, tutorialStep: params.tutorialStep }));
    }
    if (params.mode === types_1.CaptureMode.ADD_DAMAGE_1ST_SHOT) {
        return (0, jsx_runtime_1.jsx)(components_1.ZoomOutShot, { onCancel: params.onCancelAddDamage });
    }
    if ([types_1.CaptureMode.ADD_DAMAGE_2ND_SHOT, types_1.CaptureMode.ADD_DAMAGE_PART_SELECT_SHOT].includes(params.mode)) {
        return ((0, jsx_runtime_1.jsx)(components_1.CloseUpShot, { onCancel: params.onCancelAddDamage, streamDimensions: params.previewDimensions, showCounter: params.mode === types_1.CaptureMode.ADD_DAMAGE_2ND_SHOT }));
    }
    return ((0, jsx_runtime_1.jsx)(components_1.PartSelection, { onCancel: params.onCancelAddDamage, vehicleParts: params.vehicleParts, vehicleType: params.vehicleType, onValidateVehicleParts: params.onValidateVehicleParts, onAddDamagePartsSelected: params.onAddDamagePartsSelected }));
}
exports.PhotoCaptureHUDElements = PhotoCaptureHUDElements;
