"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonkActionType = void 0;
/**
 * Enumeration of the types of action that can be dispatched in the Monk state.
 */
var MonkActionType;
(function (MonkActionType) {
    /**
     * An inspection has been fetched from the API.
     */
    MonkActionType["GOT_ONE_INSPECTION"] = "got_one_inspection";
    /**
     * An inspection additional data has been updated.
     */
    MonkActionType["UPDATED_ONE_INSPECTION_ADDITIONAL_DATA"] = "updated_one_inspection_additional_data";
    /**
     * An image has been uploaded to the API.
     */
    MonkActionType["CREATED_ONE_IMAGE"] = "created_one_image";
    /**
     * One or more tasks have been updated.
     */
    MonkActionType["UPDATED_MANY_TASKS"] = "updated_many_tasks";
    /**
     * A vehicle has been updated.
     */
    MonkActionType["UPDATED_VEHICLE"] = "updated_vehicle";
    /**
     * A pricing has been uploaded to the API.
     */
    MonkActionType["CREATED_ONE_PRICING"] = "created_one_pricing";
    /**
     * A pricing has been updated.
     */
    MonkActionType["UPDATED_ONE_PRICING"] = "updated_one_pricing";
    /**
     * A pricing has been deleted.
     */
    MonkActionType["DELETED_ONE_PRICING"] = "deleted_one_pricing";
    /**
     * A damage has been uploaded to the API.
     */
    MonkActionType["CREATED_ONE_DAMAGE"] = "created_one_damage";
    /**
     * A damage has been deleted.
     */
    MonkActionType["DELETED_ONE_DAMAGE"] = "deleted_one_damage";
    /**
     * Clear and reset the state.
     */
    MonkActionType["RESET_STATE"] = "reset_state";
    /**
     * An inspection PDF has been fetched from the API.
     */
    MonkActionType["GOT_ONE_INSPECTION_PDF"] = "got_one_inspection_pdf";
})(MonkActionType = exports.MonkActionType || (exports.MonkActionType = {}));
