"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.gotOneInspectionPdf = exports.isGotOneInspectionPdfAction = void 0;
var monkAction_1 = require("./monkAction");
/**
 * Matcher function that matches a GotOneInspection while also inferring its type using TypeScript's type predicate
 * feature.
 */
function isGotOneInspectionPdfAction(action) {
    return action.type === monkAction_1.MonkActionType.GOT_ONE_INSPECTION_PDF;
}
exports.isGotOneInspectionPdfAction = isGotOneInspectionPdfAction;
/**
 * Reducer function for a GotOneInspection action.
 */
function gotOneInspectionPdf(state, action) {
    var inspections = state.inspections;
    var payload = action.payload;
    var inspection = inspections.find(function (value) { return value.id === payload.inspectionId; });
    if (inspection) {
        inspection.pdfUrl = payload.pdfUrl;
    }
    return __assign(__assign({}, state), { inspections: __spreadArray([], inspections, true) });
}
exports.gotOneInspectionPdf = gotOneInspectionPdf;
