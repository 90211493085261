"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFrameSelection = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var monitoring_1 = require("@monkvision/monitoring");
var laplaceScores_1 = require("./laplaceScores");
/**
 * Custom hook used to manage the video frame selection. Basically, every time a camera screenshot is taken, it is added
 * to the frame selection processing queue. The blurriness score of the screenshot is calculated, and the best video
 * frame (the less blurry one) is always stored in memory. Finally, every `frameSelectionInterval` milliseconds, the
 * best video frame is "selected" (to be uploaded to the API) and the process resets.
 */
function useFrameSelection(_a) {
    var handle = _a.handle, frameSelectionInterval = _a.frameSelectionInterval, onFrameSelected = _a.onFrameSelected;
    var bestScore = (0, react_1.useRef)(null);
    var bestFrame = (0, react_1.useRef)(null);
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    var processingQueue = (0, common_1.useQueue)(function (image) {
        return new Promise(function (resolve) {
            // Note : Other array-copying methods might result in performance issues
            var imagePixelsCopy = image.data.slice();
            var laplaceScores = (0, laplaceScores_1.calculateLaplaceScores)(imagePixelsCopy, image.width, image.height);
            if (bestScore.current === null || laplaceScores.std > bestScore.current) {
                bestScore.current = laplaceScores.std;
                bestFrame.current = image;
            }
            resolve();
        });
    }, { storeFailedItems: false });
    var onCaptureVideoFrame = (0, react_1.useCallback)(function () {
        processingQueue.push(handle.getImageData());
    }, [processingQueue.push]);
    (0, common_1.useInterval)(function () {
        if (bestFrame.current !== null) {
            handle
                .compressImage(bestFrame.current)
                .then(function (picture) { return onFrameSelected === null || onFrameSelected === void 0 ? void 0 : onFrameSelected(picture); })
                .catch(handleError);
        }
        bestScore.current = null;
        bestFrame.current = null;
    }, frameSelectionInterval);
    return (0, common_1.useObjectMemo)({
        processedFrames: processingQueue.totalItems - processingQueue.processingCount,
        totalProcessingFrames: processingQueue.totalItems,
        onCaptureVideoFrame: onCaptureVideoFrame,
    });
}
exports.useFrameSelection = useFrameSelection;
