"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageLayoutItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var PageLayoutItem_styles_1 = require("./PageLayoutItem.styles");
/**
 * A custom list item that is displayed in VideoCapture Intro screens.
 */
function PageLayoutItem(_a) {
    var icon = _a.icon, title = _a.title, description = _a.description;
    var _b = (0, PageLayoutItem_styles_1.usePageLayoutItemStyles)(), iconProps = _b.iconProps, titleStyle = _b.titleStyle, descriptionStyle = _b.descriptionStyle;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: PageLayoutItem_styles_1.styles['container'] }, { children: [(0, jsx_runtime_1.jsx)(common_ui_web_1.Icon, __assign({ icon: icon }, iconProps)), (0, jsx_runtime_1.jsxs)("div", __assign({ style: PageLayoutItem_styles_1.styles['labels'] }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: titleStyle }, { children: title })), (0, jsx_runtime_1.jsx)("div", __assign({ style: descriptionStyle }, { children: description }))] }))] })));
}
exports.PageLayoutItem = PageLayoutItem;
