"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhotoCaptureHUDElementsSight = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var SightSlider_1 = require("./SightSlider");
var PhotoCaptureHUDElementsSight_styles_1 = require("./PhotoCaptureHUDElementsSight.styles");
var AddDamageButton_1 = require("./AddDamageButton");
var hooks_1 = require("./hooks");
var hooks_2 = require("../../hooks");
var SightGuideline_1 = require("./SightGuideline");
var components_1 = require("../../../components");
var types_1 = require("../../../types");
/**
 * Component implementing an HUD displayed on top of the Camera preview during the PhotoCapture process when the current
 * mode is SIGHT.
 */
function PhotoCaptureHUDElementsSight(_a) {
    var sights = _a.sights, selectedSight = _a.selectedSight, _b = _a.onSelectedSight, onSelectedSight = _b === void 0 ? function () { } : _b, _c = _a.onRetakeSight, onRetakeSight = _c === void 0 ? function () { } : _c, _d = _a.onAddDamage, onAddDamage = _d === void 0 ? function () { } : _d, sightsTaken = _a.sightsTaken, previewDimensions = _a.previewDimensions, images = _a.images, addDamage = _a.addDamage, sightGuidelines = _a.sightGuidelines, enableSightGuidelines = _a.enableSightGuidelines, tutorialStep = _a.tutorialStep;
    var style = (0, hooks_1.usePhotoCaptureHUDSightPreviewStyle)({ previewDimensions: previewDimensions });
    var showSight = previewDimensions && (!tutorialStep || tutorialStep === hooks_2.TutorialSteps.SIGHT);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: PhotoCaptureHUDElementsSight_styles_1.styles['container'] }, { children: [showSight && (0, jsx_runtime_1.jsx)(common_ui_web_1.SightOverlay, { style: style.overlay, sight: selectedSight }), !tutorialStep && ((0, jsx_runtime_1.jsxs)("div", __assign({ style: style.elementsContainer }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: style.top }, { children: [(0, jsx_runtime_1.jsx)(SightGuideline_1.SightGuideline, { sightId: selectedSight.id, sightGuidelines: sightGuidelines, enableSightGuidelines: enableSightGuidelines, addDamage: addDamage }), (0, jsx_runtime_1.jsx)(AddDamageButton_1.AddDamageButton, { onAddDamage: onAddDamage, addDamage: addDamage })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: style.bottom }, { children: [(0, jsx_runtime_1.jsx)(components_1.Counter, { mode: types_1.CaptureMode.SIGHT, totalSights: sights.length, sightsTaken: sightsTaken.length }), (0, jsx_runtime_1.jsx)(SightSlider_1.SightSlider, { sights: sights, selectedSight: selectedSight, sightsTaken: sightsTaken, onSelectedSight: onSelectedSight, onRetakeSight: onRetakeSight, images: images })] }))] })))] })));
}
exports.PhotoCaptureHUDElementsSight = PhotoCaptureHUDElementsSight;
