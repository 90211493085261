"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePageLayoutItemStyles = exports.styles = exports.PAGE_LAYOUT_MAX_HEIGHT_BREAKPOINT = void 0;
var common_1 = require("@monkvision/common");
exports.PAGE_LAYOUT_MAX_HEIGHT_BREAKPOINT = 600;
exports.styles = {
    container: {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 16,
    },
    icon: {
        marginRight: 12,
    },
    labels: {
        flex: 1,
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
    },
    titleSmall: {
        __media: {
            maxHeight: exports.PAGE_LAYOUT_MAX_HEIGHT_BREAKPOINT,
        },
        fontSize: 14,
        fontWeight: 500,
    },
    description: {
        fontSize: 18,
        paddingTop: 6,
        opacity: 0.91,
        fontWeight: 300,
    },
    descriptionSmall: {
        __media: {
            maxHeight: exports.PAGE_LAYOUT_MAX_HEIGHT_BREAKPOINT,
        },
        fontSize: 12,
        fontWeight: 400,
    },
};
function usePageLayoutItemStyles() {
    var palette = (0, common_1.useMonkTheme)().palette;
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    return {
        iconProps: {
            size: 40,
            primaryColor: palette.primary.base,
            style: __assign(__assign({}, exports.styles['icon']), responsive(exports.styles['iconSmall'])),
        },
        titleStyle: __assign(__assign({}, exports.styles['title']), responsive(exports.styles['titleSmall'])),
        descriptionStyle: __assign(__assign({}, exports.styles['description']), responsive(exports.styles['descriptionSmall'])),
    };
}
exports.usePageLayoutItemStyles = usePageLayoutItemStyles;
