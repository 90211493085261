"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVideoCapturePageLayoutStyles = exports.styles = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var PageLayoutItem_styles_1 = require("./PageLayoutItem/PageLayoutItem.styles");
exports.styles = {
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    containerBackdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    logo: {
        margin: '32px 0',
        width: 80,
        height: 'auto',
    },
    logoSmall: {
        __media: {
            maxHeight: PageLayoutItem_styles_1.PAGE_LAYOUT_MAX_HEIGHT_BREAKPOINT,
        },
        display: 'none',
    },
    title: {
        fontSize: 32,
        fontWeight: 700,
        textAlign: 'center',
        padding: '0 32px 16px 32px',
    },
    titleSmall: {
        __media: {
            maxHeight: PageLayoutItem_styles_1.PAGE_LAYOUT_MAX_HEIGHT_BREAKPOINT,
        },
        fontSize: 20,
        fontWeight: 700,
        textAlign: 'center',
        padding: '10px 16px 10px 16px',
    },
    childrenContainer: {
        flex: 1,
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
    },
    confirmContainer: {
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0 32px 32px 32px',
    },
    confirmButton: {
        alignSelf: 'stretch',
        maxWidth: 400,
    },
};
function useVideoCapturePageLayoutStyles(_a) {
    var showBackdrop = _a.showBackdrop;
    var palette = (0, common_1.useMonkTheme)().palette;
    var responsive = (0, common_1.useResponsiveStyle)().responsive;
    var getLogoAttributes = (0, react_1.useCallback)(function (element) { return (0, common_1.fullyColorSVG)(element, palette.text.primary); }, [palette]);
    return {
        logoProps: {
            getAttributes: getLogoAttributes,
            style: __assign(__assign({}, exports.styles['logo']), responsive(exports.styles['logoSmall'])),
        },
        containerStyle: __assign(__assign({}, exports.styles['container']), (showBackdrop ? exports.styles['containerBackdrop'] : {})),
        titleStyle: __assign(__assign({}, exports.styles['title']), responsive(exports.styles['titleSmall'])),
    };
}
exports.useVideoCapturePageLayoutStyles = useVideoCapturePageLayoutStyles;
