"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddDamage = exports.PhotoCaptureTutorialOption = exports.CaptureWorkflow = void 0;
/**
 * The types of insepction capture workflow.
 */
var CaptureWorkflow;
(function (CaptureWorkflow) {
    /**
     * PhotoCapture workflow.
     */
    CaptureWorkflow["PHOTO"] = "photo";
    /**
     * VideoCapture workflow.
     */
    CaptureWorkflow["VIDEO"] = "video";
})(CaptureWorkflow = exports.CaptureWorkflow || (exports.CaptureWorkflow = {}));
/**
 * Enumeration of the tutorial options.
 */
var PhotoCaptureTutorialOption;
(function (PhotoCaptureTutorialOption) {
    /**
     * Photo capture is disabled.
     */
    PhotoCaptureTutorialOption["DISABLED"] = "disabled";
    /**
     * Photo capture is enabled.
     */
    PhotoCaptureTutorialOption["ENABLED"] = "enabled";
    /**
     * Photo capture is enabled only time.
     */
    PhotoCaptureTutorialOption["FIRST_TIME_ONLY"] = "first_time_only";
})(PhotoCaptureTutorialOption = exports.PhotoCaptureTutorialOption || (exports.PhotoCaptureTutorialOption = {}));
/**
 * Enumeration of the Add Damage options.
 */
var AddDamage;
(function (AddDamage) {
    /**
     * Add Damage is disabled.
     */
    AddDamage["DISABLED"] = "disabled";
    /**
     * Add Damage is enabled with Two Shot:
     * First shot for the zoom out image and second for the close up. The vehicle part will be infered.
     */
    AddDamage["TWO_SHOT"] = "two_shot";
    /**
     * Add Damage is enabled with Part select:
     * Parts must be selected before taken a single close up shot.
     */
    AddDamage["PART_SELECT"] = "part_select";
})(AddDamage = exports.AddDamage || (exports.AddDamage = {}));
