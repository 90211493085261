"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMonkApi = void 0;
var react_1 = require("react");
var common_1 = require("@monkvision/common");
var monitoring_1 = require("@monkvision/monitoring");
var api_1 = require("./api");
function handleAPIError(err, handleError) {
    var body = err.body;
    handleError(err, {
        extras: {
            body: body,
            completeResponse: JSON.stringify(body),
        },
    });
    throw err;
}
function reactify(request, config, dispatch, handleError) {
    return (0, react_1.useCallback)(function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        return request.apply(void 0, __spreadArray(__spreadArray([], params, false), [config, dispatch], false)).catch(function (err) { return handleAPIError(err, handleError); });
    }, []);
}
/**
 * Custom hook that returns a MonkApi object in which all the requests don't need a config param (since it is provided
 * as a prop to this hook), and automatically update the state (using the MonkState hook) when a state has been
 * received.
 *
 * **Note: This hook needs to have the MonkContext set up and defined in order to work properly.**
 *
 * @see MonkApi
 */
function useMonkApi(config) {
    var dispatch = (0, common_1.useMonkState)().dispatch;
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    return {
        /**
         * Fetch the details of an inspection using its ID.
         *
         * @param options The options of the request.
         */
        getInspection: reactify(api_1.MonkApi.getInspection, config, dispatch, handleError),
        /**
         * Fetch multiple inspection.
         *
         * @param options The options of the request.
         */
        getAllInspections: reactify(api_1.MonkApi.getAllInspections, config, dispatch, handleError),
        /**
         * Gets the count of inspections that match the given filters.
         *
         * @param options The options of the request.
         */
        getAllInspectionsCount: reactify(api_1.MonkApi.getAllInspectionsCount, config, dispatch, handleError),
        /**
         * Create a new inspection with the given options. See the `CreateInspectionOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see CreateInspectionOptions
         */
        createInspection: reactify(api_1.MonkApi.createInspection, config, dispatch, handleError),
        /**
         * Add a new image to an inspection.
         *
         * @param options Upload options for the image.
         */
        addImage: reactify(api_1.MonkApi.addImage, config, dispatch, handleError),
        /**
         * Update the progress status of an inspection task.
         *
         * **Note : This API call is known to sometimes fail for unknown reasons. In order to fix this, we added a retry
         * config to this API request : when failing, this request will retry itself up to 4 times (5 API calls in total),
         * with exponentially increasing delay between each request (max delay : 1.5s).**
         *
         * @param options The options of the request.
         */
        updateTaskStatus: reactify(api_1.MonkApi.updateTaskStatus, config, dispatch, handleError),
        /**
         * Start some inspection tasks that were in the NOT_STARTED status. This function actually makes one API call for
         * each task provided using the `updateTaskStatus`.
         *
         * **Note : This API call is known to sometimes fail for unknown reasons. Please take note of the details provided
         * in the TSDoc of the `updateTaskStatus` function.**
         *
         * @param options The options of the request.
         *
         * @see updateTaskStatus
         */
        startInspectionTasks: reactify(api_1.MonkApi.startInspectionTasks, config, dispatch, handleError),
        /**
         * Fetch a webapp live configuration from the API.
         *
         * @param id The ID of the live config to get.
         */
        getLiveConfig: (0, react_1.useCallback)(function (id) { return api_1.MonkApi.getLiveConfig(id).catch(function (err) { return handleAPIError(err, handleError); }); }, [handleError]),
        /**
         * Update the vehicle of an inspection.
         *
         * @param options The options of the request.
         */
        updateInspectionVehicle: reactify(api_1.MonkApi.updateInspectionVehicle, config, dispatch, handleError),
        /**
         * Update the additional data of an inspection.
         *
         * @param options The options of the request.
         */
        updateAdditionalData: reactify(api_1.MonkApi.updateAdditionalData, config, dispatch, handleError),
        /**
         * Create a new pricing with the given options. See the `CreatePricingOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see CreatePricingOptions
         */
        createPricing: reactify(api_1.MonkApi.createPricing, config, dispatch, handleError),
        /**
         * Delete a pricing with the given options. See the `DeletePricingOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see DeletePricingOptions
         */
        deletePricing: reactify(api_1.MonkApi.deletePricing, config, dispatch, handleError),
        /**
         * Update a pricing with the given options. See the `UpdatePricingOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see UpdatePricingOptions
         */
        updatePricing: reactify(api_1.MonkApi.updatePricing, config, dispatch, handleError),
        /**
         * Create a new damage with the given options. See the `CreateDamageOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see CreateDamageOptions
         */
        createDamage: reactify(api_1.MonkApi.createDamage, config, dispatch, handleError),
        /**
         * Delete a damage with the given options. See the `DeleteDamageOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see DeleteDamageOptions
         */
        deleteDamage: reactify(api_1.MonkApi.deleteDamage, config, dispatch, handleError),
        /**
         * Upload a PDF file to the API. See the `UploadPdfOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see UploadPdfOptions
         */
        uploadPdf: reactify(api_1.MonkApi.uploadPdf, config, dispatch, handleError),
        /**
         * Get a PDF file from an inspection. See the `GetPdfOptions` interface for more details.
         *
         * @param options The options of the inspection.
         * @see GetPdfOptions
         */
        getPdf: reactify(api_1.MonkApi.getPdf, config, dispatch, handleError),
    };
}
exports.useMonkApi = useMonkApi;
