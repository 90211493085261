"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehiclePart = void 0;
/**
 * Enumeration of the different vehicle part names used by Monk backend and frontend apps.
 */
var VehiclePart;
(function (VehiclePart) {
    VehiclePart["IGNORE"] = "ignore";
    VehiclePart["BACKGROUND"] = "background";
    VehiclePart["BUMPER_BACK"] = "bumper_back";
    VehiclePart["BUMPER_FRONT"] = "bumper_front";
    VehiclePart["DOOR_BACK_LEFT"] = "door_back_left";
    VehiclePart["DOOR_BACK_RIGHT"] = "door_back_right";
    VehiclePart["DOOR_FRONT_LEFT"] = "door_front_left";
    VehiclePart["DOOR_FRONT_RIGHT"] = "door_front_right";
    VehiclePart["FENDER_BACK_LEFT"] = "fender_back_left";
    VehiclePart["FENDER_BACK_RIGHT"] = "fender_back_right";
    VehiclePart["FENDER_FRONT_LEFT"] = "fender_front_left";
    VehiclePart["FENDER_FRONT_RIGHT"] = "fender_front_right";
    VehiclePart["FOG_LIGHT_BACK_LEFT"] = "fog_light_back_left";
    VehiclePart["FOG_LIGHT_BACK_RIGHT"] = "fog_light_back_right";
    VehiclePart["FOG_LIGHT_FRONT_LEFT"] = "fog_light_front_left";
    VehiclePart["FOG_LIGHT_FRONT_RIGHT"] = "fog_light_front_right";
    VehiclePart["FRONT_SPOILER"] = "front_spoiler";
    VehiclePart["GRILL"] = "grill";
    VehiclePart["GRILL_LOW"] = "grill_low";
    VehiclePart["GRILL_RADIATOR"] = "grill_radiator";
    VehiclePart["HANDLE_BACK_LEFT"] = "handle_back_left";
    VehiclePart["HANDLE_BACK_RIGHT"] = "handle_back_right";
    VehiclePart["HANDLE_FRONT_LEFT"] = "handle_front_left";
    VehiclePart["HANDLE_FRONT_RIGHT"] = "handle_front_right";
    VehiclePart["HEAD_LIGHT_LEFT"] = "head_light_left";
    VehiclePart["HEAD_LIGHT_RIGHT"] = "head_light_right";
    VehiclePart["HEADER_PANEL"] = "header_panel";
    VehiclePart["HOOD"] = "hood";
    VehiclePart["HOOK"] = "hook";
    VehiclePart["HUBCAP"] = "hubcap";
    VehiclePart["HUBCAP_BACK_LEFT"] = "hubcap_back_left";
    VehiclePart["HUBCAP_BACK_RIGHT"] = "hubcap_back_right";
    VehiclePart["HUBCAP_FRONT_LEFT"] = "hubcap_front_left";
    VehiclePart["HUBCAP_FRONT_RIGHT"] = "hubcap_front_right";
    VehiclePart["INDICATOR_LIGHT_LEFT"] = "indicator_light_left";
    VehiclePart["INDICATOR_LIGHT_RIGHT"] = "indicator_light_right";
    VehiclePart["LICENSE_PLATE_BACK"] = "license_plate_back";
    VehiclePart["LICENSE_PLATE_FRONT"] = "license_plate_front";
    VehiclePart["LOGO"] = "logo";
    VehiclePart["MIRROR_SUPPORT"] = "mirror_support";
    VehiclePart["MIRROR_LEFT"] = "mirror_left";
    VehiclePart["MIRROR_RIGHT"] = "mirror_right";
    VehiclePart["PETROL_DOOR"] = "petrol_door";
    VehiclePart["PILLAR"] = "pillar";
    VehiclePart["QUARTER_WINDOW_BACK_LEFT"] = "quarter_window_back_left";
    VehiclePart["QUARTER_WINDOW_BACK_RIGHT"] = "quarter_window_back_right";
    VehiclePart["QUARTER_WINDOW_FRONT_LEFT"] = "quarter_window_front_left";
    VehiclePart["QUARTER_WINDOW_FRONT_RIGHT"] = "quarter_window_front_right";
    VehiclePart["REAR_SPOILER"] = "rear_spoiler";
    VehiclePart["ROCKER_PANEL"] = "rocker_panel";
    VehiclePart["ROCKER_PANEL_LEFT"] = "rocker_panel_left";
    VehiclePart["ROCKER_PANEL_RIGHT"] = "rocker_panel_right";
    VehiclePart["ROOF"] = "roof";
    VehiclePart["TAIL_LIGHT_CENTER"] = "tail_light_center";
    VehiclePart["TAIL_LIGHT_LEFT"] = "tail_light_left";
    VehiclePart["TAIL_LIGHT_RIGHT"] = "tail_light_right";
    VehiclePart["TRUNK"] = "trunk";
    VehiclePart["TURN_SIGNAL_FRONT_LATERAL_LEFT"] = "turn_signal_front_lateral_left";
    VehiclePart["TURN_SIGNAL_FRONT_LATERAL_RIGHT"] = "turn_signal_front_lateral_right";
    VehiclePart["WHEEL_BACK_LEFT"] = "wheel_back_left";
    VehiclePart["WHEEL_BACK_RIGHT"] = "wheel_back_right";
    VehiclePart["WHEEL_FRONT_LEFT"] = "wheel_front_left";
    VehiclePart["WHEEL_FRONT_RIGHT"] = "wheel_front_right";
    VehiclePart["WINDOW_BACK_LEFT"] = "window_back_left";
    VehiclePart["WINDOW_BACK_RIGHT"] = "window_back_right";
    VehiclePart["WINDOW_CORNER_LEFT"] = "window_corner_left";
    VehiclePart["WINDOW_CORNER_RIGHT"] = "window_corner_right";
    VehiclePart["WINDOW_FRONT_LEFT"] = "window_front_left";
    VehiclePart["WINDOW_FRONT_RIGHT"] = "window_front_right";
    VehiclePart["WINDSHIELD_BACK"] = "windshield_back";
    VehiclePart["WINDSHIELD_FRONT"] = "windshield_front";
    VehiclePart["WIPER"] = "wiper";
    VehiclePart["WIPER_BACK"] = "wiper_back";
    VehiclePart["WIPER_FRONT"] = "wiper_front";
    VehiclePart["WHEEL"] = "wheel";
    VehiclePart["CAR_INSIDE"] = "car_inside";
    VehiclePart["DAMAGED_CAR_INSIDE"] = "damaged_car_inside";
    VehiclePart["HANDLE_BACK_CENTER"] = "handle_back_center";
    VehiclePart["RIM_BACK_LEFT"] = "rim_back_left";
    VehiclePart["RIM_BACK_RIGHT"] = "rim_back_right";
    VehiclePart["RIM_FRONT_LEFT"] = "rim_front_left";
    VehiclePart["RIM_FRONT_RIGHT"] = "rim_front_right";
    VehiclePart["INTERIOR"] = "interior";
})(VehiclePart = exports.VehiclePart || (exports.VehiclePart = {}));
