"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
var HUDButtons_styles_1 = require("../../../../components/HUDButtons/HUDButtons.styles");
exports.styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: '45%',
        paddingBottom: '0%',
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'none',
        maxWidth: '60vw',
        zIndex: 9,
    },
    containerPortrait: {
        __media: { portrait: true },
        bottom: "".concat(HUDButtons_styles_1.PHOTO_CAPTURE_HUD_BUTTONS_BAR_WIDTH * 1.5, "px"),
        right: 0,
        paddingLeft: '30%',
        paddingRight: '45%',
    },
    button: {
        margin: 10,
        zIndex: 9,
    },
};
