"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoCaptureSightState = void 0;
var react_1 = require("react");
var network_1 = require("@monkvision/network");
var monitoring_1 = require("@monkvision/monitoring");
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
var sights_1 = require("@monkvision/sights");
var analytics_1 = require("@monkvision/analytics");
var errors_1 = require("../errors");
function getCaptureTasks(captureSights, tasksBySight) {
    var tasks = [];
    captureSights.forEach(function (sight) {
        var sightTasks = tasksBySight ? tasksBySight[sight.id] : sight.tasks;
        sightTasks.forEach(function (task) {
            if (!tasks.includes(task)) {
                tasks.push(task);
            }
        });
    });
    return tasks;
}
function assertInspectionIsValid(inspectionId, response, captureSights, tasksBySight) {
    var _a, _b;
    var inspectionTasks = (_b = (_a = response.entities.tasks) === null || _a === void 0 ? void 0 : _a.filter(function (task) { return task.inspectionId === inspectionId; })) === null || _b === void 0 ? void 0 : _b.map(function (task) { return task.name; });
    if (inspectionTasks) {
        var missingTasks_1 = [];
        getCaptureTasks(captureSights, tasksBySight).forEach(function (captureTask) {
            if (!inspectionTasks.includes(captureTask)) {
                missingTasks_1.push(captureTask);
            }
        });
        if (missingTasks_1.length > 0) {
            var error = new Error("The provided inspection is missing the following tasks required by the current capture configuration : ".concat(missingTasks_1));
            error.name = errors_1.PhotoCaptureErrorName.MISSING_TASK_IN_INSPECTION;
            throw error;
        }
    }
}
function getSightsTaken(inspectionId, response) {
    var _a, _b;
    return (0, common_1.uniq)((_b = (_a = response.entities.images) === null || _a === void 0 ? void 0 : _a.filter(function (image) { return image.inspectionId === inspectionId && image.sightId; }).map(function (image) { return sights_1.sights[image.sightId]; })) !== null && _b !== void 0 ? _b : []);
}
function getLastPictureTakenUri(inspectionId, response) {
    var images = response.entities.images.filter(function (image) { return image.inspectionId === inspectionId; });
    return images && images.length > 0 ? images[images.length - 1].path : null;
}
/**
 * Custom hook used to manage the state of the PhotoCapture sights. This state is automatically fetched from the API at
 * the start of the PhotoCapture process in order to allow users to start the inspection where they left it before.
 */
function usePhotoCaptureSightState(_a) {
    var inspectionId = _a.inspectionId, captureSights = _a.captureSights, apiConfig = _a.apiConfig, loading = _a.loading, onLastSightTaken = _a.onLastSightTaken, tasksBySight = _a.tasksBySight, setIsInitialInspectionFetched = _a.setIsInitialInspectionFetched, complianceOptions = _a.complianceOptions;
    if (captureSights.length === 0) {
        throw new Error('Empty sight list given to the Monk PhotoCapture component.');
    }
    var _b = (0, react_1.useState)(0), retryCount = _b[0], setRetryCount = _b[1];
    var _c = (0, react_1.useState)(null), lastPictureTakenUri = _c[0], setLastPictureTakenUri = _c[1];
    var _d = (0, react_1.useState)(captureSights[0]), selectedSight = _d[0], setSelectedSight = _d[1];
    var _e = (0, react_1.useState)(false), isInspectionCompleted = _e[0], setIsInspectionCompleted = _e[1];
    var _f = (0, react_1.useState)([]), sightsTaken = _f[0], setSightsTaken = _f[1];
    var getInspection = (0, network_1.useMonkApi)(apiConfig).getInspection;
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    var state = (0, common_1.useMonkState)().state;
    var analytics = (0, analytics_1.useAnalytics)();
    var onFetchInspection = function (response) {
        try {
            setIsInitialInspectionFetched(true);
            assertInspectionIsValid(inspectionId, response, captureSights, tasksBySight);
            var alreadyTakenSights_1 = getSightsTaken(inspectionId, response);
            setSightsTaken(alreadyTakenSights_1);
            var notCapturedSights = captureSights.filter(function (s) { return !alreadyTakenSights_1.includes(s); });
            analytics.setUserProperties({
                alreadyTakenSights: alreadyTakenSights_1.length,
                totalSights: captureSights.length,
                sightSelected: notCapturedSights.length > 0 ? notCapturedSights[0].label : null,
            });
            analytics.setEventsProperties({ totalSights: captureSights.length });
            analytics.trackEvent('Capture Started', {
                newInspection: alreadyTakenSights_1.length === 0,
                alreadyTakenSights: alreadyTakenSights_1.length,
                totalSights: captureSights.length,
            });
            if (notCapturedSights.length > 0) {
                setSelectedSight(notCapturedSights[0]);
            }
            else {
                onLastSightTaken();
                var notCompliantSights = captureSights
                    .map(function (s) { return ({
                    sight: s,
                    image: (0, common_1.getInspectionImages)(inspectionId, state.images, undefined, true).find(function (i) { return i.inspectionId === inspectionId && i.sightId === s.id; }),
                }); })
                    .filter(function (_a) {
                    var image = _a.image;
                    return !!image &&
                        [types_1.ImageStatus.NOT_COMPLIANT, types_1.ImageStatus.UPLOAD_FAILED].includes(image.status);
                })
                    .map(function (_a) {
                    var sight = _a.sight;
                    return sight;
                });
                var sightToRetake_1 = notCompliantSights.length > 0
                    ? notCompliantSights[0]
                    : captureSights[captureSights.length - 1];
                setSightsTaken(alreadyTakenSights_1.filter(function (sight) { return sight !== sightToRetake_1; }));
                setSelectedSight(sightToRetake_1);
            }
            setLastPictureTakenUri(getLastPictureTakenUri(inspectionId, response));
            loading.onSuccess();
            setIsInspectionCompleted(!response.entities.tasks.some(function (task) {
                return task.inspectionId === inspectionId && task.status === types_1.ProgressStatus.NOT_STARTED;
            }));
        }
        catch (err) {
            handleError(err);
            loading.onError(err);
        }
    };
    (0, common_1.useAsyncEffect)(function () {
        loading.start();
        analytics.setUserProperties({ inspectionId: inspectionId });
        analytics.setEventsProperties({ inspectionId: inspectionId });
        return getInspection({
            id: inspectionId,
            compliance: complianceOptions,
        });
    }, [inspectionId, retryCount, complianceOptions], {
        onResolve: onFetchInspection,
        onReject: function (err) {
            handleError(err);
            loading.onError(err);
        },
    });
    var retryLoadingInspection = (0, react_1.useCallback)(function () {
        setRetryCount(function (value) { return value + 1; });
    }, []);
    var takeSelectedSight = (0, react_1.useCallback)(function () {
        var _a, _b;
        var isRetake = sightsTaken.includes(selectedSight);
        var updatedSightsTaken = isRetake ? sightsTaken : __spreadArray(__spreadArray([], sightsTaken, true), [selectedSight], false);
        setSightsTaken(updatedSightsTaken);
        var nextSight = captureSights.filter(function (s) { return !updatedSightsTaken.includes(s); })[0];
        analytics.trackEvent('Sight Captured', {
            order: captureSights.indexOf(selectedSight) + 1,
            alreadyTakenSights: updatedSightsTaken.length,
            totalSights: captureSights.length,
            sightId: selectedSight.id,
            sightLabel: selectedSight.label,
            nextSightId: (_a = nextSight === null || nextSight === void 0 ? void 0 : nextSight.id) !== null && _a !== void 0 ? _a : null,
            nextSightLabel: (_b = nextSight === null || nextSight === void 0 ? void 0 : nextSight.label) !== null && _b !== void 0 ? _b : null,
            retake: isRetake,
        });
        analytics.setUserProperties({
            alreadyTakenSights: updatedSightsTaken.length,
            sightSelected: selectedSight.label,
        });
        if (nextSight) {
            setSelectedSight(nextSight);
        }
        else {
            onLastSightTaken();
        }
    }, [sightsTaken, selectedSight, captureSights, onLastSightTaken]);
    var retakeSight = (0, react_1.useCallback)(function (id) {
        var sightToRetake = captureSights.find(function (sight) { return sight.id === id; });
        if (sightToRetake) {
            setSelectedSight(sightToRetake);
        }
    }, [captureSights]);
    var selectSight = function (s) {
        var _a, _b;
        var sightsNotTaken = (_a = captureSights.filter(function (sight) { return !sightsTaken.includes(sight); })) !== null && _a !== void 0 ? _a : [];
        var nextSight = (_b = sightsNotTaken.at(sightsNotTaken.indexOf(s) + 1)) !== null && _b !== void 0 ? _b : null;
        setSelectedSight(s);
        analytics.trackEvent('Sight Selected', {
            sightId: s.id,
            sightLabel: s.label,
            nextSightId: nextSight === null || nextSight === void 0 ? void 0 : nextSight.id,
            nextSightLabel: nextSight === null || nextSight === void 0 ? void 0 : nextSight.label,
        });
    };
    return (0, common_1.useObjectMemo)({
        isInspectionCompleted: isInspectionCompleted,
        setIsInspectionCompleted: setIsInspectionCompleted,
        selectedSight: selectedSight,
        sightsTaken: sightsTaken,
        selectSight: selectSight,
        takeSelectedSight: takeSelectedSight,
        lastPictureTakenUri: lastPictureTakenUri,
        setLastPictureTakenUri: setLastPictureTakenUri,
        retryLoadingInspection: retryLoadingInspection,
        retakeSight: retakeSight,
    });
}
exports.usePhotoCaptureSightState = usePhotoCaptureSightState;
