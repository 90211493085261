"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInspectionImages = void 0;
/**
 * Utility function that extracts the images of the given inspection.
 *
 * @param inspectionId The ID of the inspection to get the images of.
 * @param images Array containing every image existing in the current local state.
 * @param filterImageType The specific image type to filter by. If not provided, no type-based filtering is applied.
 * @param filterRetakes Boolean indicating if retaken pictures should be filtered out or not (default: false).
 */
function getInspectionImages(inspectionId, images, filterImageType, filterRetakes) {
    if (filterRetakes === void 0) { filterRetakes = false; }
    var inspectionImages = images.filter(function (image) { return image.inspectionId === inspectionId; });
    if (filterImageType) {
        inspectionImages = inspectionImages.filter(function (image) { return filterImageType === image.type; });
    }
    if (!filterRetakes) {
        return inspectionImages;
    }
    var filteredRetakes = {};
    inspectionImages.forEach(function (image) {
        if (image.sightId) {
            var existingImage = filteredRetakes[image.sightId];
            if (!existingImage ||
                (image.createdAt && existingImage.createdAt && image.createdAt > existingImage.createdAt)) {
                filteredRetakes[image.sightId] = image;
            }
        }
        else {
            filteredRetakes[image.id] = image;
        }
    });
    return Object.values(filteredRetakes);
}
exports.getInspectionImages = getInspectionImages;
