"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseUpShot = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var common_1 = require("@monkvision/common");
var CloseUpShot_styles_1 = require("./CloseUpShot.styles");
var hooks_1 = require("./hooks");
var Counter_1 = require("../Counter");
var CancelButton_1 = require("../CancelButton");
var types_1 = require("../../types");
/**
 * Component implementing an HUD displayed on top of the Camera preview during the PhotoCapture process when the current
 * mode is ADD_DAMAGE_2ND_SHOT | ADD_DAMAGE_PART_SELECT_SHOT.
 */
function CloseUpShot(_a) {
    var onCancel = _a.onCancel, _b = _a.showCounter, showCounter = _b === void 0 ? true : _b, streamDimensions = _a.streamDimensions;
    var t = (0, react_i18next_1.useTranslation)().t;
    var style = (0, hooks_1.useCloseUpShotStyle)();
    var aspectRatio = (0, common_1.getAspectRatio)(streamDimensions);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: CloseUpShot_styles_1.styles['container'] }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: __assign(__assign({}, CloseUpShot_styles_1.styles['frameContainer']), { aspectRatio: aspectRatio }), "data-testid": 'frame-container' }, { children: (0, jsx_runtime_1.jsx)("div", { style: style.frame }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: style.top }, { children: [showCounter && (0, jsx_runtime_1.jsx)(Counter_1.Counter, { mode: types_1.CaptureMode.ADD_DAMAGE_2ND_SHOT }), (0, jsx_runtime_1.jsx)(CancelButton_1.CancelButton, { onCancel: onCancel })] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: style.infoCloseup, "data-testid": 'label' }, { children: t('photo.hud.addDamage.infoCloseup') }))] })));
}
exports.CloseUpShot = CloseUpShot;
