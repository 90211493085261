"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVehicleModel = void 0;
var sights_1 = require("@monkvision/sights");
var types_1 = require("@monkvision/types");
/**
 * Returns the vehicle model corresponding to the given vehicle type.
 */
function getVehicleModel(vehicleType) {
    var _a;
    var ajustedVehicletype = vehicleType === types_1.VehicleType.SUV ? types_1.VehicleType.CUV : vehicleType;
    var detail = (_a = Object.entries(sights_1.vehicles)
        .filter(function (_a) {
        var type = _a[0];
        return type !== types_1.VehicleModel.AUDIA7;
    })
        .find(function (_a) {
        var details = _a[1];
        return details.type === ajustedVehicletype;
    })) === null || _a === void 0 ? void 0 : _a[1];
    if (detail === undefined) {
        throw new Error("No vehicle model found for vehicle type ".concat(ajustedVehicletype));
    }
    return detail.id;
}
exports.getVehicleModel = getVehicleModel;
