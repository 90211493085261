"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrientationEnforcer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var react_i18next_1 = require("react-i18next");
var OrientationEnforcer_styles_1 = require("./OrientationEnforcer.styles");
var hooks_1 = require("../../hooks");
/**
 * Component that enforces a certain device orientation. If the current device orientation is not equal to the one
 * passed as a prop, it will display an error on the screen asking the user to rotate their device.
 */
function OrientationEnforcer(_a) {
    var orientation = _a.orientation;
    var t = (0, react_i18next_1.useTranslation)().t;
    var containerStyle = (0, OrientationEnforcer_styles_1.useOrientationEnforcerStyles)().containerStyle;
    var isViolatingEnforcedOrientation = (0, hooks_1.useEnforceOrientation)(orientation);
    if (!isViolatingEnforcedOrientation) {
        return null;
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: containerStyle }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: OrientationEnforcer_styles_1.styles['titleContainer'] }, { children: [(0, jsx_runtime_1.jsx)(common_ui_web_1.Icon, { icon: 'rotate', primaryColor: 'text-primary', size: 30 }), (0, jsx_runtime_1.jsx)("div", __assign({ style: OrientationEnforcer_styles_1.styles['title'] }, { children: t('orientationEnforcer.title') }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: OrientationEnforcer_styles_1.styles['description'] }, { children: t('orientationEnforcer.description') }))] })));
}
exports.OrientationEnforcer = OrientationEnforcer;
