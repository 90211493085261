"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
exports.styles = {
    notCarPart: {
        display: 'none',
    },
    selectable: {
        pointerEvents: 'fill',
        cursor: 'pointer',
    },
    svg: {
        width: '100%',
        height: '100%',
    },
};
