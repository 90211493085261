"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VehicleDynamicWireframe = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var types_1 = require("@monkvision/types");
var DynamicSVG_1 = require("../DynamicSVG");
var hooks_1 = require("./hooks");
var VehicleDynamicWireframe_style_1 = require("./VehicleDynamicWireframe.style");
/**
 * Component that displays a dynamic wireframe of a vehicle, allowing the user to select parts of the vehicle.
 */
function VehicleDynamicWireframe(_a) {
    var vehicleType = _a.vehicleType, _b = _a.onClickPart, onClickPart = _b === void 0 ? function () { } : _b, _c = _a.orientation, orientation = _c === void 0 ? types_1.PartSelectionOrientation.FRONT_LEFT : _c, _d = _a.getPartAttributes, getPartAttributes = _d === void 0 ? function () { return ({}); } : _d;
    var _e = (0, hooks_1.useVehicleDynamicWireframe)({
        vehicleType: vehicleType,
        orientation: orientation,
        onClickPart: onClickPart,
        getPartAttributes: getPartAttributes,
    }), overlay = _e.overlay, getAttributes = _e.getAttributes;
    return (0, jsx_runtime_1.jsx)(DynamicSVG_1.DynamicSVG, { svg: overlay, style: VehicleDynamicWireframe_style_1.styles['svg'], getAttributes: getAttributes });
}
exports.VehicleDynamicWireframe = VehicleDynamicWireframe;
