"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddDamageButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var react_i18next_1 = require("react-i18next");
var types_1 = require("@monkvision/types");
var hooks_1 = require("../../../../hooks");
/**
 * Custom button displayed in the PhotoCapture Camera HUD that allows user to enter add damage mode.
 */
function AddDamageButton(_a) {
    var onAddDamage = _a.onAddDamage, addDamage = _a.addDamage;
    var t = (0, react_i18next_1.useTranslation)().t;
    var primaryColor = (0, hooks_1.useColorBackground)();
    return [types_1.AddDamage.TWO_SHOT, types_1.AddDamage.PART_SELECT].includes(addDamage) ? ((0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ icon: 'add', onClick: onAddDamage, "data-testid": 'monk-test-btn', primaryColor: primaryColor }, { children: t('photo.hud.sight.addDamageBtn') }))) : null;
}
exports.AddDamageButton = AddDamageButton;
