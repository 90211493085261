"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInspectionGalleryItems = void 0;
var react_1 = require("react");
var types_1 = require("@monkvision/types");
var common_1 = require("@monkvision/common");
var network_1 = require("@monkvision/network");
var DEFAULT_REFRESH_INTERVAL_MS = 1000;
function getSightSortIndex(item, inspectionSights) {
    var defaultIndex = 9999;
    if (item.isAddDamage) {
        return defaultIndex + 1;
    }
    var sightId = item.isTaken ? item.image.sightId : item.sightId;
    if (!sightId) {
        return defaultIndex;
    }
    var index = inspectionSights === null || inspectionSights === void 0 ? void 0 : inspectionSights.findIndex(function (sight) { return sight.id === sightId; });
    return index !== undefined && index >= 0 ? index : defaultIndex;
}
function needsToBeRetaken(item) {
    return (!item.isAddDamage &&
        item.isTaken &&
        [types_1.ImageStatus.UPLOAD_FAILED, types_1.ImageStatus.NOT_COMPLIANT, types_1.ImageStatus.UPLOAD_ERROR].includes(item.image.status));
}
/**
 * Compare function used to determine the order of the gallery items. The current order is :
 * 1 - Pictures that need to be retaken (if compliance is enabled), sorted by sight order
 * 2 - Taken or not taken pictures sorted by sight order
 * 3 - Additional pictures (Add Damage etc...)
 */
function compareGalleryItems(a, b, captureMode, inspectionSights) {
    var aSightIndex = getSightSortIndex(a, inspectionSights);
    var bSightIndex = getSightSortIndex(b, inspectionSights);
    if (captureMode && needsToBeRetaken(a) && !needsToBeRetaken(b)) {
        return -1;
    }
    if (captureMode && !needsToBeRetaken(a) && needsToBeRetaken(b)) {
        return 1;
    }
    return aSightIndex - bSightIndex;
}
function getItems(inspectionId, captureMode, entities, inspectionSights, addDamage, filterByImageType) {
    var images = (0, common_1.getInspectionImages)(inspectionId, entities.images, filterByImageType, captureMode);
    var items = images.map(function (image) { return ({
        isTaken: true,
        isAddDamage: false,
        image: image,
    }); });
    inspectionSights === null || inspectionSights === void 0 ? void 0 : inspectionSights.forEach(function (sight) {
        if (captureMode &&
            !items.find(function (item) { return !item.isAddDamage && item.isTaken && item.image.sightId === sight.id; })) {
            items.push({ isTaken: false, isAddDamage: false, sightId: sight.id });
        }
    });
    if (captureMode && addDamage !== types_1.AddDamage.DISABLED) {
        items.push({ isAddDamage: true });
    }
    return items.sort(function (a, b) { return compareGalleryItems(a, b, captureMode, inspectionSights); });
}
function shouldContinueToFetch(items) {
    return items.some(function (item) {
        return !item.isAddDamage &&
            item.isTaken &&
            [types_1.ImageStatus.UPLOADING, types_1.ImageStatus.COMPLIANCE_RUNNING].includes(item.image.status);
    });
}
function useInspectionGalleryItems(props) {
    var _a;
    var inspectionSights = props.captureMode ? props.sights : undefined;
    var state = (0, common_1.useMonkState)().state;
    var items = (0, react_1.useMemo)(function () {
        return getItems(props.inspectionId, props.captureMode, state, inspectionSights, props.addDamage, props.filterByImageType);
    }, [
        props.inspectionId,
        props.captureMode,
        state,
        inspectionSights,
        props.addDamage,
        props.filterByImageType,
    ]);
    var shouldFetch = (0, react_1.useMemo)(function () { return shouldContinueToFetch(items); }, items);
    (0, network_1.useInspectionPoll)({
        id: props.inspectionId,
        apiConfig: props.apiConfig,
        compliance: props.captureMode
            ? {
                enableCompliance: props.enableCompliance,
                enableCompliancePerSight: props.enableCompliancePerSight,
                useLiveCompliance: props.useLiveCompliance,
                complianceIssues: props.complianceIssues,
                complianceIssuesPerSight: props.complianceIssuesPerSight,
                customComplianceThresholds: props.customComplianceThresholds,
                customComplianceThresholdsPerSight: props.customComplianceThresholdsPerSight,
            }
            : undefined,
        delay: shouldFetch ? (_a = props.refreshIntervalMs) !== null && _a !== void 0 ? _a : DEFAULT_REFRESH_INTERVAL_MS : null,
    });
    return items;
}
exports.useInspectionGalleryItems = useInspectionGalleryItems;
