"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAspectRatio = exports.isMobileDevice = void 0;
/**
 * Checks if the current device is a mobile device.
 */
function isMobileDevice() {
    var userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.includes('mobile') ||
        userAgent.includes('android') ||
        userAgent.includes('iphone') ||
        userAgent.includes('ipad') ||
        userAgent.includes('windows phone'));
}
exports.isMobileDevice = isMobileDevice;
/**
 * Returns the aspect ratio of the stream. If not a mobile device, it will return 16/9 by default.
 */
function getAspectRatio(streamDimensions) {
    if (isMobileDevice() && streamDimensions) {
        return "".concat(streamDimensions === null || streamDimensions === void 0 ? void 0 : streamDimensions.width, "/").concat(streamDimensions === null || streamDimensions === void 0 ? void 0 : streamDimensions.height);
    }
    return '16/9';
}
exports.getAspectRatio = getAspectRatio;
