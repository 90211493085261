"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePictureTaken = void 0;
var react_1 = require("react");
var analytics_1 = require("@monkvision/analytics");
var types_1 = require("../types");
/**
 * Custom hook used to generate the callback called when the user has taken a picture to handle picture upload etc.
 */
function usePictureTaken(_a) {
    var captureState = _a.captureState, addDamageHandle = _a.addDamageHandle, uploadQueue = _a.uploadQueue, tasksBySight = _a.tasksBySight, onPictureTaken = _a.onPictureTaken;
    var trackEvent = (0, analytics_1.useAnalytics)().trackEvent;
    var selectedSightId = 'selectedSight' in captureState ? captureState.selectedSight.id : undefined;
    var takeSelectedSight = 'takeSelectedSight' in captureState ? captureState.takeSelectedSight : undefined;
    return (0, react_1.useCallback)(function (picture) {
        var _a;
        onPictureTaken === null || onPictureTaken === void 0 ? void 0 : onPictureTaken(picture);
        captureState.setLastPictureTakenUri(picture.uri);
        if (addDamageHandle.mode === types_1.CaptureMode.ADD_DAMAGE_PART_SELECT_SHOT) {
            uploadQueue.push({
                mode: addDamageHandle.mode,
                picture: picture,
                vehicleParts: addDamageHandle.vehicleParts,
            });
        }
        if (addDamageHandle.mode === types_1.CaptureMode.SIGHT && 'selectedSight' in captureState) {
            uploadQueue.push({
                mode: addDamageHandle.mode,
                picture: picture,
                sightId: captureState.selectedSight.id,
                tasks: (_a = tasksBySight === null || tasksBySight === void 0 ? void 0 : tasksBySight[captureState.selectedSight.id]) !== null && _a !== void 0 ? _a : captureState.selectedSight.tasks,
            });
        }
        if (addDamageHandle.mode === types_1.CaptureMode.ADD_DAMAGE_1ST_SHOT ||
            addDamageHandle.mode === types_1.CaptureMode.ADD_DAMAGE_2ND_SHOT) {
            uploadQueue.push({ mode: addDamageHandle.mode, picture: picture });
        }
        if (addDamageHandle.mode === types_1.CaptureMode.SIGHT && 'takeSelectedSight' in captureState) {
            captureState.takeSelectedSight();
        }
        else {
            trackEvent('AddDamage Captured', {
                mode: addDamageHandle.mode,
            });
        }
        addDamageHandle.updatePhotoCaptureModeAfterPictureTaken();
    }, [
        captureState.setLastPictureTakenUri,
        addDamageHandle.mode,
        selectedSightId,
        tasksBySight,
        uploadQueue.push,
        takeSelectedSight,
        addDamageHandle.updatePhotoCaptureModeAfterPictureTaken,
        onPictureTaken,
    ]);
}
exports.usePictureTaken = usePictureTaken;
