"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColorBackground = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
/**
 * Custom hook used to generate the background color in the inspection capture web components.
 */
function useColorBackground(opacity) {
    if (opacity === void 0) { opacity = 0.64; }
    var palette = (0, common_1.useMonkTheme)().palette;
    var clampedOpacity = Math.max(0, Math.min(opacity, 1));
    return (0, react_1.useMemo)(function () { return (0, common_1.changeAlpha)(palette.background.base, opacity); }, [palette, clampedOpacity]);
}
exports.useColorBackground = useColorBackground;
