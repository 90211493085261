"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useVehicleWalkaroundIndicatorStyles = void 0;
var PROGRESS_BAR_STROKE_WIDTH_RATIO = 0.15;
var KNOB_STROKE_WIDTH_RATIO = 0.03;
var DEFAULT_STEP_FILL_COLOR = '#6b6b6b';
var NEXT_STEP_FILL_COLOR = '#f3f3f3';
var PROGRESS_BAR_FILL_COLOR = '#18e700';
var KNOB_FILL_COLOR = '#0A84FF';
var KNOB_STROKE_COLOR = '#f3f3f3';
function getDrawingConstants(size) {
    var s = size * (1 - PROGRESS_BAR_STROKE_WIDTH_RATIO - KNOB_STROKE_WIDTH_RATIO);
    var r = s / 2;
    return {
        s: s,
        r: r,
        v: (r * Math.SQRT2) / 2,
        offset: (size / 2) * (KNOB_STROKE_WIDTH_RATIO + PROGRESS_BAR_STROKE_WIDTH_RATIO),
    };
}
function getStepsProps(_a) {
    var alpha = _a.alpha, size = _a.size;
    var _b = getDrawingConstants(size), s = _b.s, r = _b.r, v = _b.v, offset = _b.offset;
    var sharedProps = {
        r: (size * PROGRESS_BAR_STROKE_WIDTH_RATIO) / 2,
        strokeWidth: 0,
        fill: DEFAULT_STEP_FILL_COLOR,
    };
    var steps = [
        { alpha: 0, cx: r, cy: s },
        { alpha: 45, cx: r + v, cy: r + v },
        { alpha: 90, cx: s, cy: r },
        { alpha: 135, cx: r + v, cy: r - v },
        { alpha: 180, cx: r, cy: 0 },
        { alpha: 225, cx: r - v, cy: r - v },
        { alpha: 270, cx: 0, cy: r },
        { alpha: 315, cx: r - v, cy: r + v },
    ].map(function (step) { return ({
        alpha: step.alpha,
        props: __assign({ cx: step.cx + offset, cy: step.cy + offset }, sharedProps),
    }); });
    var nextStep = steps === null || steps === void 0 ? void 0 : steps.find(function (step) { return step.alpha > alpha; });
    if (nextStep) {
        nextStep.props.fill = NEXT_STEP_FILL_COLOR;
    }
    return steps;
}
function getProgressBarProps(_a) {
    var alpha = _a.alpha, size = _a.size;
    var _b = getDrawingConstants(size), r = _b.r, offset = _b.offset;
    var circumference = r * 2 * Math.PI;
    var dashSize = (alpha * circumference) / 360;
    return {
        r: r,
        cx: offset + size / 2,
        cy: size / 2,
        strokeLinecap: 'round',
        strokeWidth: size * PROGRESS_BAR_STROKE_WIDTH_RATIO,
        stroke: PROGRESS_BAR_FILL_COLOR,
        fill: 'none',
        strokeDasharray: "".concat(dashSize, "px ").concat(circumference - dashSize, "px"),
        transform: "scale(1 -1) translate(0 -".concat(size + offset, ") rotate(-90 ").concat(offset + size / 2, " ").concat(offset + size / 2, ")"),
    };
}
function getKnobProps(_a) {
    var alpha = _a.alpha, size = _a.size;
    var _b = getDrawingConstants(size), r = _b.r, offset = _b.offset;
    var theta = (alpha * Math.PI) / 180 - Math.PI / 2;
    return {
        r: (size * PROGRESS_BAR_STROKE_WIDTH_RATIO) / 2,
        cx: offset + r * (1 + Math.cos(theta)),
        cy: offset + r * (1 - Math.sin(theta)),
        fill: KNOB_FILL_COLOR,
        strokeWidth: size * KNOB_STROKE_WIDTH_RATIO,
        stroke: KNOB_STROKE_COLOR,
    };
}
function useVehicleWalkaroundIndicatorStyles(_a) {
    var alpha = _a.alpha, size = _a.size;
    return {
        svgProps: {
            width: size,
            height: size,
            viewBox: "0 0 ".concat(size, " ").concat(size),
        },
        steps: getStepsProps({ alpha: alpha, size: size }),
        progressBarProps: getProgressBarProps({ alpha: alpha, size: size }),
        knobProps: getKnobProps({ alpha: alpha, size: size }),
    };
}
exports.useVehicleWalkaroundIndicatorStyles = useVehicleWalkaroundIndicatorStyles;
