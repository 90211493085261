"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
var HUDButtons_styles_1 = require("../HUDButtons/HUDButtons.styles");
exports.styles = {
    container: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
    },
    top: {
        position: 'absolute',
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '10px',
        zIndex: '9',
        top: '0',
        right: '0',
        left: '0',
    },
    topLandscape: {
        __media: { landscape: true },
        right: "".concat(HUDButtons_styles_1.PHOTO_CAPTURE_HUD_BUTTONS_BAR_WIDTH * 2, "px"),
    },
    infoBtn: {
        position: 'absolute',
        margin: '20px',
        bottom: '0',
    },
    infoBtnPortrait: {
        __media: { portrait: true },
        bottom: "".concat(HUDButtons_styles_1.PHOTO_CAPTURE_HUD_BUTTONS_BAR_WIDTH * 1.5, "px"),
    },
    svg: {
        width: '15%',
    },
};
