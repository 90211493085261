"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CaptureScreen = exports.CaptureMode = void 0;
/**
 * Enum of the different picture taking modes that the PhotoCapture or DamageDisclosure component can be in.
 */
var CaptureMode;
(function (CaptureMode) {
    /**
     * SIGHT mode : user is asked to take a picture of its vehicle following a given Sight.
     */
    CaptureMode["SIGHT"] = "sight";
    /**
     * ADD_DAMAGE_1ST_SHOT mode : user is asked to take a picture centered on a damage, far away from the vehicle.
     */
    CaptureMode["ADD_DAMAGE_1ST_SHOT"] = "add_damage_1st_shot";
    /**
     * ADD_DAMAGE_2ND_SHOT mode : user is asked to take a zoomed picture of a damage on the car.
     */
    CaptureMode["ADD_DAMAGE_2ND_SHOT"] = "add_damage_2nd_shot";
    /**
     * ADD_DAMAGE_PART_SELECT mode : user is asked to select car parts to take a picture of.
     */
    CaptureMode["ADD_DAMAGE_PART_SELECT"] = "add_damage_part_select";
    /**
     * ADD_DAMAGE_PART_SELECT_SHOT mode : user is asked to take a close-up picture of a damage on the car part.
     */
    CaptureMode["ADD_DAMAGE_PART_SELECT_SHOT"] = "add_damage_part_select_shot";
})(CaptureMode = exports.CaptureMode || (exports.CaptureMode = {}));
/**
 * Enum of the different capture screen available in the capture process.
 */
var CaptureScreen;
(function (CaptureScreen) {
    /**
     * Camera scren.
     */
    CaptureScreen["CAMERA"] = "camera";
    /**
     * Gallery screen.
     */
    CaptureScreen["GALLERY"] = "gallery";
})(CaptureScreen = exports.CaptureScreen || (exports.CaptureScreen = {}));
