"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ZoomOutShot = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var ZoomOutShot_styles_1 = require("./ZoomOutShot.styles");
var hooks_1 = require("../../hooks");
var types_1 = require("../../types");
var assets_1 = require("../../assets");
var hooks_2 = require("./hooks");
var Counter_1 = require("../Counter");
var CancelButton_1 = require("../CancelButton");
/**
 * Component implementing an HUD displayed on top of the Camera preview during the PhotoCapture process when the current
 * mode is ADD_DAMAGE_1ST_SHOT.
 */
function ZoomOutShot(_a) {
    var onCancel = _a.onCancel;
    var _b = (0, react_1.useState)(true), showInfoPopup = _b[0], setShowInfoPopup = _b[1];
    var t = (0, react_i18next_1.useTranslation)().t;
    var primaryColor = (0, hooks_1.useColorBackground)();
    var style = (0, hooks_2.useZoomOutShotStyles)();
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: ZoomOutShot_styles_1.styles['container'] }, { children: [(0, jsx_runtime_1.jsx)(common_ui_web_1.DynamicSVG, { style: ZoomOutShot_styles_1.styles['svg'], svg: assets_1.crosshairSvg }), (0, jsx_runtime_1.jsxs)("div", __assign({ style: style.top }, { children: [(0, jsx_runtime_1.jsx)(Counter_1.Counter, { mode: types_1.CaptureMode.ADD_DAMAGE_1ST_SHOT }), (0, jsx_runtime_1.jsx)(CancelButton_1.CancelButton, { onCancel: onCancel })] })), showInfoPopup && ((0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ icon: 'close', primaryColor: primaryColor, style: style.infoBtn, onClick: function () { return setShowInfoPopup(false); } }, { children: t('photo.hud.addDamage.infoBtn') })))] })));
}
exports.ZoomOutShot = ZoomOutShot;
