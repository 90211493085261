"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoCaptureProcessing = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_i18next_1 = require("react-i18next");
var VideoCapturePageLayout_1 = require("../VideoCapturePageLayout");
var VideoCaptureProcessing_styles_1 = require("./VideoCaptureProcessing.styles");
function getLabel(processingProgress, uploadingProgress) {
    if (processingProgress < 1) {
        return 'video.processing.processing';
    }
    return uploadingProgress < 1 ? 'video.processing.uploading' : 'video.processing.success';
}
/**
 * Component displayed at the end of the VideoCapture process, used to display progress indicators for the processing
 * and uploading of video frames.
 */
function VideoCaptureProcessing(_a) {
    var inspectionId = _a.inspectionId, processedFrames = _a.processedFrames, totalProcessingFrames = _a.totalProcessingFrames, uploadedFrames = _a.uploadedFrames, totalUploadingFrames = _a.totalUploadingFrames, loading = _a.loading, onComplete = _a.onComplete;
    var processingProgress = processedFrames / totalProcessingFrames;
    var uploadingProgress = uploadedFrames / totalUploadingFrames;
    var progress = processingProgress < 1 ? processingProgress : uploadingProgress;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _b = (0, VideoCaptureProcessing_styles_1.useVideoCaptureProcessingStyles)(progress), containerStyle = _b.containerStyle, progressBarContainerStyle = _b.progressBarContainerStyle, progressBarStyle = _b.progressBarStyle;
    var confirmButtonProps = {
        onClick: onComplete,
        loading: loading.isLoading,
        disabled: processingProgress < 1 || uploadingProgress < 1 || !!loading.error,
        children: t('video.processing.done'),
    };
    return ((0, jsx_runtime_1.jsx)(VideoCapturePageLayout_1.VideoCapturePageLayout, __assign({ showBackdrop: true, showTitle: false, confirmButtonProps: confirmButtonProps }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ style: containerStyle }, { children: [!loading.error && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ style: VideoCaptureProcessing_styles_1.styles['labelContainer'] }, { children: [(0, jsx_runtime_1.jsx)("div", { children: t(getLabel(processingProgress, uploadingProgress)) }), (0, jsx_runtime_1.jsxs)("div", { children: [Math.floor(progress * 100), "%"] })] })), (0, jsx_runtime_1.jsx)("div", __assign({ style: progressBarContainerStyle }, { children: (0, jsx_runtime_1.jsx)("div", { style: progressBarStyle, "data-testid": 'progress-bar' }) }))] })), loading.error && ((0, jsx_runtime_1.jsxs)("div", __assign({ style: VideoCaptureProcessing_styles_1.styles['errorMessage'] }, { children: [t('video.processing.error'), " ", inspectionId] })))] })) })));
}
exports.VideoCaptureProcessing = VideoCaptureProcessing;
