"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoCapture = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var camera_web_1 = require("@monkvision/camera-web");
var monitoring_1 = require("@monkvision/monitoring");
var VideoCapture_styles_1 = require("./VideoCapture.styles");
var VideoCapturePermissions_1 = require("./VideoCapturePermissions");
var VideoCaptureHUD_1 = require("./VideoCaptureHUD");
var hooks_1 = require("../hooks");
var hooks_2 = require("./hooks");
var VideoCaptureScreen;
(function (VideoCaptureScreen) {
    VideoCaptureScreen["PERMISSIONS"] = "permissions";
    VideoCaptureScreen["CAPTURE"] = "capture";
})(VideoCaptureScreen || (VideoCaptureScreen = {}));
// No ts-doc for this component : the component exported is VideoCaptureHOC
function VideoCapture(_a) {
    var inspectionId = _a.inspectionId, apiConfig = _a.apiConfig, additionalTasks = _a.additionalTasks, startTasksOnComplete = _a.startTasksOnComplete, enforceOrientation = _a.enforceOrientation, _b = _a.minRecordingDuration, minRecordingDuration = _b === void 0 ? 15000 : _b, _c = _a.maxRetryCount, maxRetryCount = _c === void 0 ? 3 : _c, _d = _a.enableFastWalkingWarning, enableFastWalkingWarning = _d === void 0 ? true : _d, _e = _a.enablePhoneShakingWarning, enablePhoneShakingWarning = _e === void 0 ? true : _e, _f = _a.fastWalkingWarningCooldown, fastWalkingWarningCooldown = _f === void 0 ? 1000 : _f, _g = _a.phoneShakingWarningCooldown, phoneShakingWarningCooldown = _g === void 0 ? 1000 : _g, onComplete = _a.onComplete, lang = _a.lang;
    (0, common_1.useI18nSync)(lang);
    var _h = (0, react_1.useState)(VideoCaptureScreen.PERMISSIONS), screen = _h[0], setScreen = _h[1];
    var _j = (0, react_1.useState)(false), isRecording = _j[0], setIsRecording = _j[1];
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    var _k = (0, hooks_2.useFastMovementsDetection)({
        isRecording: isRecording,
        enableFastWalkingWarning: enableFastWalkingWarning,
        enablePhoneShakingWarning: enablePhoneShakingWarning,
        fastWalkingWarningCooldown: fastWalkingWarningCooldown,
        phoneShakingWarningCooldown: phoneShakingWarningCooldown,
    }), onDeviceOrientationEvent = _k.onDeviceOrientationEvent, fastMovementsWarning = _k.fastMovementsWarning, onWarningDismiss = _k.onWarningDismiss;
    var _l = (0, common_1.useDeviceOrientation)({ onDeviceOrientationEvent: onDeviceOrientationEvent }), alpha = _l.alpha, requestCompassPermission = _l.requestCompassPermission;
    var startTasksLoading = (0, common_1.useLoadingState)();
    var startTasks = (0, hooks_1.useStartTasksOnComplete)({
        inspectionId: inspectionId,
        apiConfig: apiConfig,
        additionalTasks: additionalTasks,
        startTasksOnComplete: startTasksOnComplete,
        loading: startTasksLoading,
    });
    var allowRedirect = (0, common_1.usePreventExit)(true).allowRedirect;
    var handleComplete = function () {
        startTasks()
            .then(function () {
            allowRedirect();
            onComplete === null || onComplete === void 0 ? void 0 : onComplete();
        })
            .catch(function (err) {
            startTasksLoading.onError(err);
            handleError(err);
        });
    };
    var hudProps = {
        inspectionId: inspectionId,
        maxRetryCount: maxRetryCount,
        apiConfig: apiConfig,
        minRecordingDuration: minRecordingDuration,
        enforceOrientation: enforceOrientation,
        isRecording: isRecording,
        setIsRecording: setIsRecording,
        alpha: alpha,
        fastMovementsWarning: fastMovementsWarning,
        onWarningDismiss: onWarningDismiss,
        startTasksLoading: startTasksLoading,
        onComplete: handleComplete,
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: VideoCapture_styles_1.styles['container'] }, { children: [screen === VideoCaptureScreen.PERMISSIONS && ((0, jsx_runtime_1.jsx)(VideoCapturePermissions_1.VideoCapturePermissions, { requestCompassPermission: requestCompassPermission, onSuccess: function () { return setScreen(VideoCaptureScreen.CAPTURE); } })), screen === VideoCaptureScreen.CAPTURE && ((0, jsx_runtime_1.jsx)(camera_web_1.Camera, { HUDComponent: VideoCaptureHUD_1.VideoCaptureHUD, hudProps: hudProps }))] })));
}
exports.VideoCapture = VideoCapture;
