"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDamageDisclosureState = void 0;
var react_1 = require("react");
var network_1 = require("@monkvision/network");
var monitoring_1 = require("@monkvision/monitoring");
var common_1 = require("@monkvision/common");
var types_1 = require("@monkvision/types");
function getLastPictureTakenUri(inspectionId, response) {
    var images = response.entities.images.filter(function (image) { return image.inspectionId === inspectionId && image.type === types_1.ImageType.CLOSE_UP; });
    return images && images.length > 0 ? images[images.length - 1].path : null;
}
/**
 * Custom hook used to manage the state of the DamageDisclosure. This state is automatically fetched from the API at
 * the start of the DamageDisclosure process in order to allow users to start the inspection where they left it before.
 */
function useDamageDisclosureState(_a) {
    var inspectionId = _a.inspectionId, apiConfig = _a.apiConfig, loading = _a.loading, complianceOptions = _a.complianceOptions;
    var _b = (0, react_1.useState)(0), retryCount = _b[0], setRetryCount = _b[1];
    var _c = (0, react_1.useState)(null), lastPictureTakenUri = _c[0], setLastPictureTakenUri = _c[1];
    var getInspection = (0, network_1.useMonkApi)(apiConfig).getInspection;
    var handleError = (0, monitoring_1.useMonitoring)().handleError;
    var onFetchInspection = function (response) {
        try {
            setLastPictureTakenUri(getLastPictureTakenUri(inspectionId, response));
            loading.onSuccess();
        }
        catch (err) {
            handleError(err);
            loading.onError(err);
        }
    };
    (0, common_1.useAsyncEffect)(function () {
        loading.start();
        return getInspection({
            id: inspectionId,
            compliance: complianceOptions,
        });
    }, [inspectionId, retryCount, complianceOptions], {
        onResolve: onFetchInspection,
        onReject: function (err) {
            handleError(err);
            loading.onError(err);
        },
    });
    var retryLoadingInspection = (0, react_1.useCallback)(function () {
        setRetryCount(function (value) { return value + 1; });
    }, []);
    return (0, common_1.useObjectMemo)({
        lastPictureTakenUri: lastPictureTakenUri,
        setLastPictureTakenUri: setLastPictureTakenUri,
        retryLoadingInspection: retryLoadingInspection,
    });
}
exports.useDamageDisclosureState = useDamageDisclosureState;
