"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = exports.ICON_SIZE = void 0;
exports.ICON_SIZE = 40;
exports.styles = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
    },
    wireframeContainer: {
        height: '90%',
        width: '100%',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    leftArrowContainer: {
        display: 'flex',
    },
    leftArrow: {
        zIndex: 1,
    },
    spacer: { width: "".concat(exports.ICON_SIZE, "px") },
    rightArrow: {
        position: 'absolute',
        right: 0,
    },
};
