"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartSelection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var react_i18next_1 = require("react-i18next");
var PartSelection_styles_1 = require("./PartSelection.styles");
var hooks_1 = require("../../hooks");
/**
 * Component that displays a vehicle wireframe on top of the Camera Preview that is used
 * to select the parts of the vehicle that the user wants to take a picture of.
 */
function PartSelection(_a) {
    var vehicleType = _a.vehicleType, vehicleParts = _a.vehicleParts, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.onCancel, onCancel = _c === void 0 ? function () { } : _c, _d = _a.onAddDamagePartsSelected, onAddDamagePartsSelected = _d === void 0 ? function () { } : _d, _e = _a.onValidateVehicleParts, onValidateVehicleParts = _e === void 0 ? function () { } : _e, _f = _a.maxSelectableParts, maxSelectableParts = _f === void 0 ? 1 : _f;
    var palette = (0, common_1.useMonkTheme)().palette;
    var _g = (0, react_i18next_1.useTranslation)(), i18n = _g.i18n, t = _g.t;
    var backgroundColor = (0, hooks_1.useColorBackground)(0.9);
    var buttonColor = (0, hooks_1.useColorBackground)();
    if (!vehicleType) {
        throw new Error('Vehicle type state is not found from useMonkAppState');
    }
    var instruction = maxSelectableParts === 1 ? 'photo.hud.addDamage.selectPart' : 'photo.hud.addDamage.selectParts';
    return disabled ? null : ((0, jsx_runtime_1.jsxs)("div", __assign({ style: __assign(__assign({}, PartSelection_styles_1.styles['container']), { background: backgroundColor }) }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: PartSelection_styles_1.styles['vehicleSelect'] }, { children: (0, jsx_runtime_1.jsx)(common_ui_web_1.VehiclePartSelection, { vehicleType: vehicleType, maxSelectableParts: maxSelectableParts, onPartsSelected: onAddDamagePartsSelected }) })), (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, { primaryColor: buttonColor, onClick: onCancel, style: PartSelection_styles_1.styles['closeBtn'], icon: 'close' }), (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, { primaryColor: vehicleParts.length ? palette.primary.base : palette.text.disabled, onClick: onValidateVehicleParts, style: PartSelection_styles_1.styles['validateBtn'], disabled: vehicleParts.length === 0, icon: 'check' }), (0, jsx_runtime_1.jsxs)("div", __assign({ style: PartSelection_styles_1.styles['labelsContainer'] }, { children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ primaryColor: palette.text.white, secondaryColor: palette.text.black, style: PartSelection_styles_1.styles['tutoLabel'] }, { children: t(instruction) })) }), vehicleParts.length > 0 && ((0, jsx_runtime_1.jsx)("span", __assign({ style: PartSelection_styles_1.styles['partsLabel'] }, { children: (0, jsx_runtime_1.jsx)(common_ui_web_1.Button, __assign({ primaryColor: palette.text.white, secondaryColor: palette.text.black }, { children: vehicleParts
                                .map(function (part) { return common_1.vehiclePartLabels[part][(0, common_1.getLanguage)(i18n.language)]; })
                                .join(', ') })) })))] }))] })));
}
exports.PartSelection = PartSelection;
