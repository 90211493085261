"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Icon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_1 = require("@monkvision/common");
var react_1 = require("react");
var DynamicSVG_1 = require("../components/DynamicSVG");
var assets_1 = require("./assets");
function getSvg(icon) {
    var asset = assets_1.MonkIconAssetsMap[icon];
    if (!asset) {
        throw new Error("Unknown icon name : ".concat(icon));
    }
    return asset;
}
/**
 * Icon component use to easily place an SVG icon into your page by just specifying the name if the icon to place.
 */
function Icon(_a) {
    var icon = _a.icon, _b = _a.size, size = _b === void 0 ? 50 : _b, _c = _a.primaryColor, primaryColor = _c === void 0 ? '#000000' : _c, passThroughProps = __rest(_a, ["icon", "size", "primaryColor"]);
    var utils = (0, common_1.useMonkTheme)().utils;
    var getAttributes = (0, react_1.useCallback)(function (element) { return (0, common_1.fullyColorSVG)(element, utils.getColor(primaryColor)); }, [primaryColor, utils.getColor]);
    return ((0, jsx_runtime_1.jsx)(DynamicSVG_1.DynamicSVG, __assign({ getAttributes: getAttributes, svg: getSvg(icon), width: size, height: size }, passThroughProps)));
}
exports.Icon = Icon;
