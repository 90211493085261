"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoCaptureRecording = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var common_ui_web_1 = require("@monkvision/common-ui-web");
var VideoCaptureRecordingStyles_1 = require("./VideoCaptureRecordingStyles");
function formatRecordingDuration(durationMs) {
    var totalSeconds = Math.floor(durationMs / 1000);
    var totalMinutes = Math.floor(totalSeconds / 60);
    var remainingSeconds = totalSeconds % 60;
    return "".concat(totalMinutes.toString().padStart(2, '0'), ":").concat(remainingSeconds
        .toString()
        .padStart(2, '0'));
}
/**
 * HUD used in recording mode displayed on top of the camera in the VideoCaputre process.
 */
function VideoCaptureRecording(_a) {
    var walkaroundPosition = _a.walkaroundPosition, isRecording = _a.isRecording, isRecordingPaused = _a.isRecordingPaused, recordingDurationMs = _a.recordingDurationMs, onClickRecordVideo = _a.onClickRecordVideo, onClickTakePicture = _a.onClickTakePicture, tooltip = _a.tooltip;
    var _b = (0, VideoCaptureRecordingStyles_1.useVideoCaptureRecordingStyles)({ isRecording: isRecording }), container = _b.container, indicators = _b.indicators, recordingDuration = _b.recordingDuration, controls = _b.controls, takePictureFlash = _b.takePictureFlash, walkaroundIndicator = _b.walkaroundIndicator, showTakePictureFlash = _b.showTakePictureFlash, tooltipPosition = _b.tooltipPosition;
    var handleTakePictureClick = function () {
        showTakePictureFlash();
        onClickTakePicture === null || onClickTakePicture === void 0 ? void 0 : onClickTakePicture();
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ style: container }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: indicators }, { children: (isRecording || isRecordingPaused) && ((0, jsx_runtime_1.jsx)("div", __assign({ style: recordingDuration }, { children: formatRecordingDuration(recordingDurationMs) }))) })), (0, jsx_runtime_1.jsxs)("div", __assign({ style: controls }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ style: walkaroundIndicator, "data-testid": 'walkaround-indicator-container' }, { children: (0, jsx_runtime_1.jsx)(common_ui_web_1.VehicleWalkaroundIndicator, { alpha: walkaroundPosition }) })), (0, jsx_runtime_1.jsx)(common_ui_web_1.RecordVideoButton, { isRecording: isRecording, onClick: onClickRecordVideo, tooltip: tooltip, tooltipPosition: tooltipPosition }), (0, jsx_runtime_1.jsx)(common_ui_web_1.TakePictureButton, { onClick: handleTakePictureClick, disabled: !isRecording })] })), (0, jsx_runtime_1.jsx)("div", { style: takePictureFlash })] })));
}
exports.VideoCaptureRecording = VideoCaptureRecording;
