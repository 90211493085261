"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePhotoCaptureImages = void 0;
var common_1 = require("@monkvision/common");
var react_1 = require("react");
/**
 * Custom hook that returns the current images taken in the inspection (it filters retakes etc.).
 */
function usePhotoCaptureImages(inspectionId) {
    var state = (0, common_1.useMonkState)().state;
    return (0, react_1.useMemo)(function () { return (0, common_1.getInspectionImages)(inspectionId, state.images, undefined, true); }, [state.images, inspectionId]);
}
exports.usePhotoCaptureImages = usePhotoCaptureImages;
